import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
import moment from "moment";
const { REACT_APP_ENV } = process.env;

export const login = createAsyncThunk(
    "user/login",
    async (data) => {
        const res = await DataService.create(data);
        const resp = res.data;
        return resp;
    }
);

export const userlogin = createAsyncThunk(
    "auth/user",
    async (token) => {
        const result = await DataService.userLogin(token);
        const user = result.data
        localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({ 'token': token, 'user': user, 'lastInteractionTime': moment() }))
        return result.data

    }
);

export const logout = createAsyncThunk("user/logout", async () => {
    localStorage.removeItem(`${REACT_APP_ENV}_rtms`);
    localStorage.removeItem(`menuData`);
    localStorage.removeItem(`ces_candidate`);
    localStorage.removeItem(`${REACT_APP_ENV}_call_filter`);
    localStorage.removeItem(`${REACT_APP_ENV}_all_call_details_pagiation`);
    const res = await DataService.logout();
    return res.data;
}
);



const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: [],
        authData: [],
        authLoggedIn: false,
        logoutUser: [],
        status: STATUSES.IDLE,
        error: '',
        loginError:'',
        logoutErr: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(login.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.loginError = !action.payload.data ? action.payload : ""
                state.token = !action.payload.token ? "" : action.payload;
                //state.authLoggedIn = true
            })

            .addCase(login.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.loginError = action.error
            })


            .addCase(userlogin.pending, (state, action) => {
                 state.status = STATUSES.LOADING;
            })
            .addCase(userlogin.fulfilled, (state, action) => {
                let result = action.payload
                if(result.status === 200){
                    state.authLoggedIn = true
                    state.status = STATUSES.SUCCESS;
                    state.authData = action.payload;
                }else{
                    state.authLoggedIn = false
                    state.status = STATUSES.FAILURE;
                    state.error = action.payload;
                }
                // state.authLoggedIn =!action.payload.data ? false : true
                // state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                // state.error = !action.payload.data ? action.payload : ""
                // state.authData = !action.payload.data ? "" : action.payload;
            })

            .addCase(userlogin.rejected, (state, action) => {
                state.authLoggedIn = false
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            ///logout 
            .addCase(logout.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(logout.fulfilled, (state, action) => {
                localStorage.removeItem(`${REACT_APP_ENV}_rtms`);
                state.authLoggedIn = false
                state.logoutUser = action.payload;
                state.status = STATUSES.SUCCESS;
            })
            .addCase(logout.rejected, (state, action) => {
                state.logoutErr = action.error;
                state.status = STATUSES.FAILURE;
            })
    },
});


export default authSlice.reducer;