import React from 'react'
import "./report.scss"
import { DataGrid, GridToolbarContainer, GridCsvExportMenuItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { MonthWiseAttendanceReportColumns } from "../../components/datatable-coulms/tableCoulmns";
import { monthlyAttandanceReport } from "../../redux/features/calendarSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import { Button, FormControl, TablePagination, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';
import LoaderForm from '../../components/common-function/LoaderForm';
import { ArrowBack, Download } from '@mui/icons-material';
import TablePaginationActions from '../../components/TablePaginationActions';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
const { REACT_APP_API_BASEURL } = process.env;

function MonthWiseAttendanceReport() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [list, setList] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const { data } = useSelector(state => state.resource)
  const [resourceId, setResourceId] = useState('select resource');
  const [loader, setLoader] = useState(true)
  const [value, setValue] = useState();
  const [minToDate, setMinToDate] = useState([null])
  const [startMaxDate, setStartMaxDate] = useState([null])
  const [fromDate, setFromDate] = useState([null])
  const [toDate, setToDate] = useState([null])
  const [downliadFile, setDownloadFile] = useState(false)
  const { status, error, monthlyAttandanceReportList } = useSelector(state => state.calender)
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  const [filterQuery, setFilterQuery] = useState(`{,"order_by":{"column":"-id"}, "skip":0,"limit":25}`)
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setLoader(false)
    }
  }, [error])

  if (loader === true && status === `${STATUSES.SUCCESS}_monthlyAttandanceReportList` && list && list.length === 0 && monthlyAttandanceReportList && monthlyAttandanceReportList.data) {
    setLoader(false)
    let reportRows = monthlyAttandanceReportList.data.rows
    let reportCount = monthlyAttandanceReportList.data.count
    let reportList = []

    reportRows && reportRows.length > 0 && reportRows.map(e => {
      return reportList.push(e)
    })

    setRowCount(reportCount)
    reportList.length > 0 && setList(reportList)
  }

  useEffect(() => {
    daysInMonth(moment().format('MM'), moment().format('YYYY'))
    let monthDays = new Date(moment().format('YYYY'), moment().format('MM'), 0).getDate()
    let startMonth = `${moment().format('YYYY')}-${moment().format('MM')}-1`
    let endMonth = `${moment().format('YYYY')}-${moment().format('MM')}-${monthDays}`

    let filter = `{"filter":{"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"skip":0,"limit":${limit}}`
    setFilterQuery(filter)
    dispatch(monthlyAttandanceReport(filter))

    dispatch(resourceList(`{"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`))
    if (status === STATUSES.FAILURE) {
      setLoader(false)
      errorHandling(error, navigate)
    }

  }, [])

  const setYearAndMonth = (newValue) => {
    const d = new Date(newValue);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    daysInMonth(month, year);
    let monthDays = new Date(year, month, 0).getDate()
    let startMonth = (`${year}-${month}-1`)
    let endMonth = (`${year}-${month}-${monthDays}`)

    if (resourceId > 0) {
      setLoader(true)
      let filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"skip":0,"limit":${limit}}`
      setList([])
      setFilterQuery(filter)
      dispatch(monthlyAttandanceReport(filter))
      setValue(newValue)
    } else {
      setLoader(true)
      let filter = `{"filter":{"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"skip":0,"limit":${limit}}`
      setList([])
      setFilterQuery(filter)
      dispatch(monthlyAttandanceReport(filter))
      setValue(newValue)
    }
  }

  const handleChange = (evt) => {
    if (evt) {
      const { name, value } = evt.target || evt;
      if (name === 'resourceId') {
        setLoader(true)
        setList([])
        let filter = `{"filter":{"resource_id":{"value":"${value}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`
        setFilterQuery(filter)
        dispatch(monthlyAttandanceReport(filter))
        setResourceId(value);
      }
    } else {
      setLoader(true)
      setResourceId('Select resource');
      setList([])
      let filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`
      setFilterQuery(filter)
      dispatch(monthlyAttandanceReport(filter))
    }
  }

  const daysInMonth = (month, year) => {
    let monthDays = new Date(year, month, 0).getDate()
    setMinToDate(`${year}-${month}-1`)
    setStartMaxDate(`${year}-${month}-${monthDays}`)
    setFromDate(`${year}-${month}-1`)
    setToDate(`${year}-${month}-${monthDays}`)
    return new Date(year, month, 0).getDate();
  }

  const setToDateFunction = (newValue) => {
    let to_date = new Date(newValue)
    let from_date = new Date(fromDate)
    let filter = '';
    setLoader(true)
    setToDate(to_date)
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`;
    }
    setList([])
    setFilterQuery(filter)
    dispatch(monthlyAttandanceReport(filter))
  }

  const setFromDateFunction = (newValue) => {
    let from_date = new Date(newValue);
    let to_date = startMaxDate;
    let filter = '';
    setLoader(true)
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`;
    }
    setFromDate(from_date)
    setMinToDate(newValue)
    setList([])
    setFilterQuery(filter)
    dispatch(monthlyAttandanceReport(filter))
  }

  const downloadCsvFile = () => {
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`
    }
    setDownloadFile(true)
    dispatch(monthlyAttandanceReport(filter))
  }


  //custom pagination
  const handlePagination = (data) => {
    setLoader(true)
    setCurrentPage(data)
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`
    }
    let newFilterData = JSON.parse(filter);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setLimit(limit)
    setList([])
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(monthlyAttandanceReport(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setLoader(true)
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    setList([])
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${newPageSize.target.value}}`
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${newPageSize.target.value}}`
    }
    let newFilterData = JSON.parse(filter);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(monthlyAttandanceReport(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={rowCount || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  const handleClick = (url) => {
    setDownloadFile(false)
    window.open(url);
  }

  if (downliadFile === true) {
    let api_url = monthlyAttandanceReportList && monthlyAttandanceReportList.data.download_api_url
    if (REACT_APP_API_BASEURL && api_url) {
      let url = REACT_APP_API_BASEURL + api_url;
      if (url) {
        handleClick(url.replace("api//", "api/"))
      }
    }
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridCsvExportMenuItem style={{ backgroundColor: '#3f51b5', color: '#fff' }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="report">
      <Sidebar />
      <div className="reportContainer">
        <Navbar />

        <div className="datatable">
          <div className="top-headings">
            <h3>Monthly Attendance Report</h3>
            <div>
              <RefreshButton api={monthlyAttandanceReport} filter={filterQuery} />
              <Tooltip title={"Back"}>
                <button onClick={() => navigate(-1)} className="btn-add-new ">
                  <ArrowBack />
                </button>
              </Tooltip>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "30px", marginBottom: "30px" }}>

            {/*year and month section*/}
            <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
              <DatePicker
                views={['month', 'year']}
                label="Year and Month"
                minDate={dayjs('2012-03-01')}
                value={value}
                onChange={(newValue) => {
                  setYearAndMonth(newValue);
                }}
                format="YYYY-MM"
                renderInput={(params) => <TextField disabled={true} {...params} helperText={null} />}
              />
            </LocalizationProvider>

            {/*select resource section*/}
            <FormControl style={{ flexBasis: "20%" }}>
              <Autocomplete
                id="controllable-states-demo"
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                options={data && data.data && data.data.rows.map((e) => {
                  return { name: "resourceId", value: e.id, label: e.name + "(" + e.email + ")" }
                })}
                renderInput={(params) => <TextField {...params} label="Select resource" />}
              />
            </FormControl>

            {/*from date section*/}
            <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
              <DatePicker
                label="From Date"
                // minDate={startMinDate}
                // maxDate={startMaxDate}
                value={fromDate}
                onChange={(newValue) => {
                  setFromDateFunction(newValue);
                }}
                format="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {/*to date section*/}
            <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
              <DatePicker
                minDate={minToDate}
                // maxDate={startMaxDate}
                label="To Date"
                value={toDate}
                onChange={(newValue) => {
                  setToDateFunction(newValue);
                }}
                format="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {/*download with server section*/}
            <Button startIcon={<Download />} style={{ backgroundColor: '#3f51b5', flexBasis: "14%", color: '#fff', padding: "14px", }} onClick={() => downloadCsvFile()}>CSV(server)</Button>
          </div>

          <ToastContainer />
          {(loader === true || status === STATUSES.LOADING) && <LoaderForm />}
          <DataGrid
            className="datagrid"
            rows={list || []}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            columns={MonthWiseAttendanceReportColumns}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            getRowId={(row) => row.emp_code + row.resource_id + row.attendance_month}
            components={{
              Toolbar: CustomToolbar,
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
          />
        </div>
      </div>
    </div>
  )

}
export default MonthWiseAttendanceReport
