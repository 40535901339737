import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const getAllJobPostList = createAsyncThunk("job-post/all-list", async (data) => {
    const res = await DataService.all_job_post_list(data);
    return res.data;
});

export const getJobPostList = createAsyncThunk("job-post/list", async (data) => {
    const res = await DataService.job_post_list(data);
    return res.data;
});

export const jobPostCreate = createAsyncThunk("job-post/create", async (data) => {
    const res = await DataService.job_post_create(data);
    return res.data;
});

export const jobPostEdit = createAsyncThunk("job-post/edit", async (id) => {
    const res = await DataService.job_post_edit(id);
    return res.data;
});

export const jobPostUpdate = createAsyncThunk("job-post/update", async (data) => {
    const res = await DataService.job_post_update(data.ID, data.addvalues);
    return res.data;
});

export const jobPostArchive = createAsyncThunk("job-post/archive", async (data) => {
    const res = await DataService.job_post_archive(data.ID, data.status);
    return res.data;
});

export const getProjectMappingDomain = createAsyncThunk("project/mapping-domain", async (data) => {
    const res = await DataService.mapping_domain(data);
    return res.data;
});

const jobPostSlice = createSlice({
    name: "jobpost",
    initialState: {
        jobPost_AllList: [],
        jobPost_List: [],
        jobPost_Create: [],
        jobPost_Edit: [],
        jobPost_Update: [],
        jobPost_Archive: [],
        status: STATUSES.IDLE,
        error: '',
        mappingDomain_List: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            ///all job post  list
            .addCase(getAllJobPostList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllJobPostList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.jobPost_AllList = action.payload;
            })
            .addCase(getAllJobPostList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd job post list
            .addCase(getJobPostList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getJobPostList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.jobPost_List = action.payload;
            })
            .addCase(getJobPostList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///job post create
            .addCase(jobPostCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobPostCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jobPost_Create`;
                state.jobPost_Create = action.payload;
            })
            .addCase(jobPostCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job post edit
            .addCase(jobPostEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobPostEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.jobPost_Edit = action.payload;
            })
            .addCase(jobPostEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job post Update
            .addCase(jobPostUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobPostUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jobPost_Update`;
                state.jobPost_Update = action.payload;
            })
            .addCase(jobPostUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job post archive
            .addCase(jobPostArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobPostArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jobPost_Archive`;
                state.jobPost_Archive = action.payload;
            })
            .addCase(jobPostArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///mapping domain get api
            .addCase(getProjectMappingDomain.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getProjectMappingDomain.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_mappingDomain_List`;
                let array = action.payload
                let domainArray = []
                if (array && array.data && array.data.rows) {
                    domainArray = array.data.rows.map((element) => {
                        return element
                    })
                }
                state.mappingDomain_List = domainArray;
            })
            .addCase(getProjectMappingDomain.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});

export default jobPostSlice.reducer;