import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage"
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
const { REACT_APP_ENV } = process.env;
const user = LocalStorageDataService.userDetail()

export const getTaskList = createAsyncThunk("task/list", async (filterData) => {
    const res = await DataService.get_task_list(filterData);
    return res.data;
});

export const getMYTaskList = createAsyncThunk("my-task/list", async (filterData) => {
    if (filterData === 'type') {
        return []
    }
    const res = await DataService.get_my_task_list(filterData);
    return res.data;
});
export const getMYTaskLists = createAsyncThunk("my-task/lists", async (filterData) => {
    if (filterData === 'type') {
        return []
    }
    const res = await DataService.get_my_task_list(filterData);
    return res.data;
});


export const getMYEditTaskList = createAsyncThunk("my-edit-task/list", async (filterData) => {
    const res = await DataService.get_my_task_list(filterData);
    return res.data;
});

export const getMYEpicTaskList = createAsyncThunk("my-epic-task/list", async (filterData) => {
    if (filterData === 'type') {
        return []
    }
    const res = await DataService.get_my_task_list(filterData);
    return res.data;
});


export const getMYEpicTaskListByIDs = createAsyncThunk("my-epic-task/list-by-ids", async (filterData) => {
    if (filterData === 'type') {
        return []
    }
    const res = await DataService.get_my_task_list(filterData);
    return res.data;
});


export const taskViewById = createAsyncThunk("task/view", async (id) => {
    const res = await DataService.get_task_By_id(id, DefaultFilterColumns.TASK_DETAILS_COLUMNS);
    return res.data;

    // let datas = 
    // {
    //     "data":{
    //        "id":11537,
    //        "jira_issue_id":null,
    //        "reported_by_id":112,
    //        "ticket_key":"senior-php-developer-11537",
    //        "ticket_jira_key":null,
    //        "project_id":45,
    //        "description":"show_resume",
    //        "summary":"1678001085Sudeep's Resume (4) (6th copy)",
    //        "issue_type_id":12,
    //        "parent_issue_id":null,
    //        "assignee_id":127,
    //        "assignee_jira_id":null,
    //        "reported_by_jira_id":null,
    //        "story_point":null,
    //        "sprint":null,
    //        "fixed_version":null,
    //        "labels":null,
    //        "priority":null,
    //        "original_estimate":null,
    //        "components":null,
    //        "is_jira_issue":false,
    //        "last_jira_status":null,
    //        "job_id":null,
    //        "status_id":40,
    //        "status_name":"Backlog",
    //        "epic_id":"11527",
    //        "status":40,
    //        "created_on":"2023-03-05T07:25:01.412828Z",
    //        "created_by":112,
    //        "updated_on":"2023-03-05T07:37:33.737552Z",
    //        "updated_by":null,
    //        "issue_type":{
    //           "id":12,
    //           "jira_id":10001,
    //           "icon_url":"https://unicodesystems.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10315?size=medium",
    //           "issue_name":"Story",
    //           "project":null,
    //           "is_jira_issue_types":true,
    //           "status":true,
    //           "created_on":"2022-10-17T18:36:15.117941Z",
    //           "created_by":2
    //        },
    //        "assignee":{
    //           "id":127,
    //           "display_name":"Anshika Khare",
    //           "name":"Anshika Khare",
    //           "email":"anshika@unicodesystems.org",
    //           "contact_no":"6307757288",
    //           "date_of_birth":"2022-11-01",
    //           "tms_role_id":4,
    //           "department_id":2,
    //           "status":true,
    //           "jira_account":null,
    //           "password":"Unicode@123",
    //           "avatar_path":null,
    //           "is_super_admin":false,
    //           "last_login":null,
    //           "alternternate_contact":null,
    //           "doj":"2021-10-04",
    //           "address":"Lucknow",
    //           "employment_type_id":1,
    //           "gender":"F",
    //           "emp_code":"C-5008",
    //           "user_id":129,
    //           "created_on":"2023-01-03T06:56:33.527851Z",
    //           "created_by":129,
    //           "updated_on":null,
    //           "updated_by":null,
    //           "department":{
    //              "id":2,
    //              "name":"Human Resource Department",
    //              "code":"HRD",
    //              "description":"Human Resource Department",
    //              "status":true,
    //              "created_on":"2022-11-01T09:15:15.870988Z",
    //              "created_by":1
    //           },
    //           "tms_role":{
    //              "id":4,
    //              "role_name":"Agent",
    //              "role_key":"agent",
    //              "role_description":"Agent",
    //              "status":true,
    //              "created_on":"2023-01-02T09:06:16.269915Z",
    //              "created_by":1,
    //              "updated_on":null,
    //              "updated_by":null
    //           },
    //           "employment_type":{
    //              "id":1,
    //              "employment_type":"Regular",
    //              "status":true,
    //              "created_on":"2022-10-17T17:08:22.903531Z",
    //              "created_by":1,
    //              "updated_on":null,
    //              "updated_by":null
    //           },
    //           "user":{
    //              "id":129,
    //              "name":"Anshika Khare",
    //              "email":"anshika@unicodesystems.org"
    //           },
    //           "agent_id":"0501541700003",
    //           "cug_ph_no":"7860224400",
    //           "shift":{
    //              "id":1,
    //              "shift_name":"Regular Shift",
    //              "shift_key":"shift-1",
    //              "start_time":"09:30:00",
    //              "end_time":"19:15:00",
    //              "duration":580,
    //              "timing_display":"09:30 AM - 07:15 PM",
    //              "status":true,
    //              "created_on":"2022-11-09T04:12:13.161822Z",
    //              "created_by":1
    //           },
    //           "country":null,
    //           "state":null,
    //           "city":null,
    //           "reporting_manager":112
    //        },
    //        "job":null,
    //        "reported_by":{
    //           "id":112,
    //           "display_name":"Abhishek Srivastava",
    //           "name":"Abhishek Srivastava",
    //           "email":"abhishek@unicodesystems.org",
    //           "contact_no":"1234567890",
    //           "date_of_birth":"2022-11-04",
    //           "tms_role_id":3,
    //           "department_id":2,
    //           "status":true,
    //           "jira_account":"631f2bb5cf721d09b015ce5a",
    //           "password":"Unicode@123",
    //           "avatar_path":null,
    //           "is_super_admin":false,
    //           "last_login":null,
    //           "alternternate_contact":null,
    //           "doj":"2022-11-04",
    //           "address":"Lucknow",
    //           "employment_type_id":1,
    //           "gender":"M",
    //           "emp_code":"5035",
    //           "user_id":113,
    //           "created_on":"2023-01-02T10:55:51.377066Z",
    //           "created_by":1,
    //           "updated_on":null,
    //           "updated_by":null,
    //           "department":{
    //              "id":2,
    //              "name":"Human Resource Department",
    //              "code":"HRD",
    //              "description":"Human Resource Department",
    //              "status":true,
    //              "created_on":"2022-11-01T09:15:15.870988Z",
    //              "created_by":1
    //           },
    //           "tms_role":{
    //              "id":3,
    //              "role_name":"HR",
    //              "role_key":"hr",
    //              "role_description":"HR",
    //              "status":true,
    //              "created_on":"2023-01-02T09:05:51.540898Z",
    //              "created_by":1,
    //              "updated_on":null,
    //              "updated_by":null
    //           },
    //           "employment_type":{
    //              "id":1,
    //              "employment_type":"Regular",
    //              "status":true,
    //              "created_on":"2022-10-17T17:08:22.903531Z",
    //              "created_by":1,
    //              "updated_on":null,
    //              "updated_by":null
    //           },
    //           "user":{
    //              "id":113,
    //              "name":"Abhishek Srivastava",
    //              "email":"abhishek@unicodesystems.org"
    //           },
    //           "agent_id":"0501541700030",
    //           "cug_ph_no":"9151799989",
    //           "shift":{
    //              "id":1,
    //              "shift_name":"Regular Shift",
    //              "shift_key":"shift-1",
    //              "start_time":"09:30:00",
    //              "end_time":"19:15:00",
    //              "duration":580,
    //              "timing_display":"09:30 AM - 07:15 PM",
    //              "status":true,
    //              "created_on":"2022-11-09T04:12:13.161822Z",
    //              "created_by":1
    //           },
    //           "country":null,
    //           "state":null,
    //           "city":null,
    //           "reporting_manager":2
    //        },
    //        "project":{
    //           "id":45,
    //           "project_name":"Senior PHP Developer",
    //           "is_jira_project":false,
    //           "project_key":"senior-php-developer",
    //           "project_type_id":2,
    //           "workflow_id":1,
    //           "jira_project_id":null,
    //           "description":"<p>Senior PHP Developer</p>\n\n<p>Skills Required:</p>\n\n<p>- PHP</p>\n\n<p>- JS</p>\n\n<p>- MySql</p>\n\n<p>- HTML</p>\n\n<p>- CSS</p>\n\n<p>- GIT</p>",
    //           "avatar_urls":null,
    //           "style":null,
    //           "department_id":2,
    //           "is_private":true,
    //           "simplified":true,
    //           "status":true,
    //           "created_on":"2023-03-05T12:10:41Z",
    //           "created_by":112,
    //           "updated_on":"2023-03-05T07:36:26.362854Z",
    //           "updated_by":112,
    //           "workflow":{
    //              "id":1,
    //              "name":"Recruitment Hiring process WorkFlow",
    //              "code":"recruitment-hiring-process-workflow",
    //              "description":"This is Recruitment Hiring process WorkFlow",
    //              "status":true,
    //              "created_on":"2023-01-02T09:58:10.700489Z",
    //              "created_by":1,
    //              "updated_on":null,
    //              "updated_by":null
    //           },
    //           "project_type":{
    //              "id":2,
    //              "project_type_name":"business",
    //              "description":"",
    //              "status":true,
    //              "created_on":"2022-11-08T05:53:00.814789Z",
    //              "created_by":2,
    //              "updated_on":null,
    //              "updated_by":null
    //           },
    //           "department":{
    //              "id":2,
    //              "name":"Human Resource Department",
    //              "code":"HRD",
    //              "description":"Human Resource Department",
    //              "status":true,
    //              "created_on":"2022-11-01T09:15:15.870988Z",
    //              "created_by":1
    //           }
    //        },
    //        "show_resume":true,
    //        "attachments":[
    //           {
    //              "id":2170,
    //              "jira_issue_id":null,
    //              "rtms_issue_id":11537,
    //              "file_name":"1678001085Sudeep's Resume (4) (6th copy).pdf",
    //              "api_url":"/recruitment/resume/download/7478",
    //              "is_jira_attachment":false,
    //              "resource_id":112,
    //              "jira_account_id":null,
    //              "mime_type":"application/pdf",
    //              "size":"61683",
    //              "status":true,
    //              "created_on":"2023-03-05T07:25:02.216567Z",
    //              "created_by":112,
    //              "updated_on":"2023-03-05T07:25:02.230778Z",
    //              "updated_by":null
    //           }
    //        ],
    //        "comments":[

    //        ],
    //        "epic_details":{
    //           "project_id":45,
    //           "rtms_key":"senior-php-developer-11527",
    //           "id":11527,
    //           "summary":"Senior PHP Developer",
    //           "job_id":19
    //        },
    //        "status_data":[
    //           {
    //              "transition_id":null,
    //              "status_id":41,
    //              "status_name":"On Hold"
    //           },
    //           {
    //              "transition_id":null,
    //              "status_id":42,
    //              "status_name":"In Progress"
    //           },
    //           {
    //              "transition_id":null,
    //              "status_id":40,
    //              "status_name":"Backlog"
    //           }
    //        ],
    //        "candidate_info":[
    //           {
    //              "id":6496,
    //              "file_upload_id":7478,
    //              "candidate_name":"1678001085Sudeep's Resume (4) (6th copy)",
    //              "given_name":"1678001085Sudeep's Resume (4) (6th copy)",
    //              "family_name":"1678001085Sudeep's Resume (4) (6th copy)",
    //              "objective":null,
    //              "plaintext":null,
    //              "html":null,
    //              "status":true,
    //              "created_on":"2023-03-05T07:25:01.391866Z",
    //              "created_by":1,
    //              "updated_on":"2023-03-05T07:25:01.398137Z",
    //              "updated_by":null
    //           }
    //        ],
    //        "candidate_phone":[

    //        ],
    //        "candidate_email":[

    //        ]
    //     },
    //     "message":"Success",
    //     "status":200
    //  }   

    // return datas

});

export const project_List = createAsyncThunk("project/list", async () => {
    const res = await DataService.project_list_for_task();
    return res.data;
});
export const main_Project_List = createAsyncThunk("mainproject/list", async () => {
    const res = await DataService.project_list();
    return res.data;
});

export const project_Jira_List = createAsyncThunk("project/jira-list", async () => {
    const res = await DataService.project_jira_list();
    return res.data;
});


export const jireStatus = createAsyncThunk("jira/status", async (data) => {
    const res = await DataService.jira_status(data);
    return res.data;
});

export const getTaskType = createAsyncThunk("task-type/list", async (id) => {
    if (id === null) {
        let data = { "data": { "rows": [] } }
        return data
    }
    const res = await DataService.get_task_type_list(id);
    return res.data;
});

export const getSprintList = createAsyncThunk("sprint/list", async () => {
    const res = await DataService.get_sprint_list();
    return res.data;
});


export const postTaskCreate = createAsyncThunk("task/create", async (data) => {
    const res = await DataService.create_task(data);
    return res.data;
});

export const postTeamMemberCreate = createAsyncThunk("team-member/create", async (data) => {
    const res = await DataService.create_team_member(data);
    return res.data;
});

export const getTeamMemberList = createAsyncThunk("team-member/list", async (data) => {
    if (data === 0) {
        return []
    }
    const res = await DataService.team_member_list(data);
    return res.data;
});

export const postTeamMemberUpdate = createAsyncThunk("team-member/update", async (data) => {
    let id = data.id
    delete data['id']
    const res = await DataService.update_team_member(id, data);
    return res.data;
});


export const taskUpdateById = createAsyncThunk("task/update", async (data) => {
    const res = await DataService.update_task(data.id, data.addvalues);
    return res.data;
});
export const taskAssigneeUpdateById = createAsyncThunk("task-assignee/update", async (data) => {
    const res = await DataService.task_assignee_update(data.issue_id, data.resource_id, data.assign_to_me);
    return res.data;
});

export const taskStatusUpdate = createAsyncThunk("task-status/update", async (data) => {
    let id = data.issue_id
    let valu = { from_status_id: data.from_status_id, to_status_id: data.to_status_id, transition_id: data.transition_id }
    const res = await DataService.task_status_update(id, valu);
    return res.data;
});

export const getCommentId = createAsyncThunk("task/comment", async (data) => {
    const res = await DataService.jira_comments_by_id(data);
    return res.data;
})

export const syncProjectAndSprintTask = createAsyncThunk("task-project/sync", async (data) => {
    if (data.type === 'project') {
        const res = await DataService.sync_task_project(data.id);
        return res.data;
    } else {
        const res = await DataService.sync_task_sprint(data.id);
        return res.data;
    }

});

export const taskArchive = createAsyncThunk("task/archive", async (id) => {
    const res = await DataService.task_archive(id);
    return res.data;
});


export const syncTaskByJiraIssueId = createAsyncThunk("task-syn/jira-id", async (id) => {
    const res = await DataService.task_sync_by_jira_id(id);
    return res.data;
});

export const allResource = createAsyncThunk("resource-all", async (id) => {
    const res = await DataService.resource_list_for_task();
    return res.data;
});
export const allIssuetype = createAsyncThunk("all-issue-type", async (id) => {
    const res = await DataService.issue_type_list();
    return res.data;
});

export const defaultTaskColumn = createAsyncThunk("set-task-column", async (data) => {
    return data;
});

export const resourceProjectList = createAsyncThunk("resource-project/list", async (filter) => {
    const res = await DataService.resource_project_list(filter);
    return res.data;
});

export const getResourceWiseProjectList = createAsyncThunk("resource-wise-project/list", async (filter) => {
    const res = await DataService.resource_project_list(filter);
    return res.data;
});


export const getTaskStoryList = createAsyncThunk("task-story/list", async (filterData) => {
    try {
        const res = await DataService.get_task_list(filterData);
        return res.data;
    } catch (e) { return }
});

export const getMemberList = createAsyncThunk("member/list", async (filterData) => {
    const res = await DataService.get_member_list(filterData);
    return res.data;
});

export const postTaskFileCreate = createAsyncThunk("task-file/create", async (filterData) => {
    const res = await DataService.create_task_file(filterData);
    return res.data;
});

export const taskWorkFlowStatusList = createAsyncThunk("task-status-workflow/list", async (filterData) => {
    const res = await DataService.work_flow_status_list(filterData);
    return res.data;
});

export const getDefaultWorkFlowStatusList = createAsyncThunk("get-default-status-workflow/list", async (filterData) => {
    const res = await DataService.get_default_work_flow_status_list(filterData);
    return res.data;
});

export const getEpicListByprojectId = createAsyncThunk("all-epic/list", async (data) => {
    if (data === null) {
        let data = { "data": { "rows": [] } }
        return data
    }
    const res = await DataService.get_epic_list_for_upload_resume(data.project_id, data.issue_type);
    return res.data;
});
export const getZoomMeetingDetails = createAsyncThunk("zoom-meeting/details", async (data) => {
    const res = await DataService.get_zoom_meeting(data);
    return res.data;
})

export const getEmailLogsDetails = createAsyncThunk("email-log/details", async (data) => {
    const res = await DataService.get_email_log(data);
    return res.data;
})

export const getCallLogDetails = createAsyncThunk("call-log/details", async (data) => {
    const res = await DataService.get_my_call_log(data);
    return res.data;
})

export const postCommentCreate = createAsyncThunk("comment/post", async (data) => {
    const res = await DataService.post_comment(data);
    return res.data;
})

export const getTaskHistory = createAsyncThunk("history/list", async (data) => {
    const res = await DataService.get_history_list(data);
    return res.data;
})

export const getCallLogDetailById = createAsyncThunk("call-log/detail-by-id", async (data) => {
    const res = await DataService.get_my_call_log(data);
    return res.data;
})

export const getMyJobList = createAsyncThunk("my-job/list", async (filterData) => {
    const res = await DataService.get_my_job_list(filterData);
    return res.data;
});

//Where status 0 -> pending, 1 -> File Inprogress, 2 => Parsed, 3 => Processing Done, 4 => Invalid File, 5 => Unable to Process
export const getFileUploadList = createAsyncThunk("upload-file/list", async (filterData) => {
    const res = await DataService.get_upload_file_list(filterData);
    return res.data;
});

export const createRemindStory = createAsyncThunk("story-remind/create", async (data) => {
    const res = await DataService.post_remind_data(data);
    return res.data;
});

export const getRemainderList = createAsyncThunk("remainder/list", async (data) => {
    const res = await DataService.get_reminder_list(data);
    return res.data;
});

export const remainderUpdateById = createAsyncThunk("remainder/update", async (data) => {
    const res = await DataService.update_reminder(data.id, data.addvalues);
    return res.data;
});

export const getProjectWorkflowStatus = createAsyncThunk("projects-workflow-status/list", async (id) => {
    const res = await DataService.get_project_workflow_status(id);
    return res.data;
});



const taskSlice = createSlice({
    name: "taskData",
    initialState: {
        taskList: [],
        taskData: [],
        projectList: [],
        jireStatusList: [],
        taskTypeList: [],
        sprintList: [],
        createTask: [],
        syncTaskList: [],
        task_archive: [],
        updateTaskData: [],
        projectJiraList: [],
        taskSyncByJira: [],
        commentById: [],
        myTaskList: [],
        taskAssigneeUpdate: [],
        taskStatusData: [],
        mainProjectList: [],
        allIssueList: [],
        resourceProject_list: [],
        taskStoryList: [],
        dataLoader: STATUSES.IDLE,
        status: STATUSES.IDLE,
        error: '',
        teamMemberStatus: '',
        defaultTaskColumns: {},
        createTeamMember: [],
        createTaskFile: [],
        memberList: [],
        updateTeamMember: [],
        workFlowStatusList: [],
        defaultWorkflowStatusList: [],
        zoomMeetingDetailList: [],
        callLogDetailList: [],
        createComment: [],
        epicList: [],
        taskHistoryList: [],
        teamMemberList: [],
        callDetailById: [],
        resourceWiseProjectList: [],
        myJobList: [],
        uploadFileList: [],
        myEpicTaskList: [],
        myEditTaskList: [],
        emailLogDetailList: [],
        createNewProjectList: [],
        createReminder: [],
        remainderList: [],
        remainderUpdate: [],
        defaultLoader: false,
        newData: [],
        myTaskLists: [],
        projectWorkflowStatusList: [],
        defaultLoaderForMyjoList: false,
        projectWorkflowStatusList_status:false,
        myEpicTaskListByIDs:[]

    },
    reducers: {},
    extraReducers(builder) {
        builder

            .addCase(getTaskList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTaskList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                let array = action.payload;
                let newArray = [];
                let newArray2 = []
                let res = array.data && array.data.rows;
                let count = array.data && array.data.count;
                if (res && res.length > 0) {
                    res.map(e => {
                        return newArray.push({
                            id: e.id,
                            project: e.project && e.project.id,
                            project_name: e.project && e.project.project_name,
                            reported_by: e.reported_by && e.reported_by.id,
                            reported_by_name: e.reported_by && e.reported_by.display_name,
                            is_jira_issue: e.is_jira_issue,
                            issue_type: e.issue_type && e.issue_type.id,
                            issue_type_name: e.issue_type && e.issue_type.issue_name,
                            issue_type_icon: e.issue_type && e.issue_type.icon_url ? e.issue_type.icon_url : "",
                            status: e.status_id,
                            status_id: e.status_id,
                            status_name: e.status_name,
                            ticket_key: e.ticket_key,
                            summary: e.summary,
                            jira_issue_id: e.jira_issue_id,
                            ticket_jira_key: e.ticket_jira_key,
                            sprint: e.sprint && e.sprint.id ? e.sprint.id : '',
                            sprint_name: e.sprint && e.sprint.sprint_name ? e.sprint.sprint_name : '',
                            story_point: e.story_point,
                            created_on: e.created_on,
                            updated_on: e.updated_on,
                            assignee: e.assignee && e.assignee_id,
                            assignee_name: e.assignee && e.assignee.display_name,
                            priority: e.priority,
                            job_id: e.job_id,
                            job: e.job

                        })
                    })
                }
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.taskList = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getTaskList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //// get mytask list 


            /// task details by id 
            .addCase(taskViewById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(taskViewById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_taskData`;
                state.taskData = action.payload;
            })
            .addCase(taskViewById.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_taskData`
                state.error = action.error
            })

            /// project list
            .addCase(project_List.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(project_List.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.projectList = action.payload;
            })
            .addCase(project_List.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// Jira Status
            .addCase(jireStatus.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jireStatus.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jireStatusList`;
                const newData = action.payload
                const filterData = newData.data.rows;
                //const result = filterData.filter(x => x.is_jira_status === true)
                state.jireStatusList = filterData;
                state.newData = action.payload
            })
            .addCase(jireStatus.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// Task Type Status
            .addCase(getTaskType.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTaskType.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.taskTypeList = action.payload
            })
            .addCase(getTaskType.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// Sprint List
            .addCase(getSprintList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSprintList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.sprintList = action.payload
            })
            .addCase(getSprintList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// Create Task
            .addCase(postTaskCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postTaskCreate.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.createTask = action.payload
            })
            .addCase(postTaskCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            /// sync Task by sprint and project
            .addCase(syncProjectAndSprintTask.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(syncProjectAndSprintTask.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.syncTaskList = action.payload
            })
            .addCase(syncProjectAndSprintTask.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///task archive
            .addCase(taskArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(taskArchive.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.task_archive = action.payload;
            })
            .addCase(taskArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update task data
            .addCase(taskUpdateById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(taskUpdateById.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.updateTaskData = action.payload;
            })
            .addCase(taskUpdateById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///update task data
            .addCase(project_Jira_List.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(project_Jira_List.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.projectJiraList = action.payload;
            })
            .addCase(project_Jira_List.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Task Sync by Jira issue Id
            .addCase(syncTaskByJiraIssueId.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(syncTaskByJiraIssueId.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.taskSyncByJira = action.payload;
            })
            .addCase(syncTaskByJiraIssueId.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///Task comment get  by Id
            .addCase(getCommentId.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCommentId.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_commentById`;
                state.commentById = action.payload;
            })
            .addCase(getCommentId.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////////my task by id
            .addCase(getMYTaskList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true
            })
            .addCase(getMYTaskList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                state.defaultLoader = false
                let array = action.payload;
                let newArray = [];
                let newArray2 = []
                let res = array.data && array.data.rows;
                let count = array.data && array.data.count;
                if (res && res.length > 0) {
                    res.map(e => {
                        return newArray.push({
                            id: e.id,
                            project: e.project && e.project.id,
                            project_name: e.project && e.project.project_name,
                            reported_by: e.reported_by && e.reported_by.id,
                            reported_by_name: e.reported_by && e.reported_by.display_name,
                            is_jira_issue: e.is_jira_issue,
                            issue_type: e.issue_type && e.issue_type.id,
                            issue_type_name: e.issue_type && e.issue_type.issue_name,
                            issue_type_icon: e.issue_type && e.issue_type.icon_url ? e.issue_type.icon_url : "",
                            status: e.status_id,
                            status_id: e.status_id,
                            status_name: e.status_name,
                            ticket_key: e.ticket_key,
                            summary: e.summary,
                            jira_issue_id: e.jira_issue_id,
                            ticket_jira_key: e.ticket_jira_key,
                            sprint: e.sprint && e.sprint.id ? e.sprint.id : '',
                            sprint_name: e.sprint && e.sprint.sprint_name ? e.sprint.sprint_name : '',
                            story_point: e.story_point,
                            created_on: e.created_on,
                            updated_on: e.updated_on,
                            assignee: e.assignee && e.assignee_id,
                            assignee_name: e.assignee && e.assignee.display_name,
                            priority: e.priority,
                            job_id: e.job_id,
                            job: e.job
                        })
                    })
                }
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.myTaskList = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getMYTaskList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoader = false

            })
            ///ne api edit task 
            //////////////my task by id
            .addCase(getMYEditTaskList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMYEditTaskList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                let array = action.payload;
                let newArray = [];
                let newArray2 = []
                let res = array.data && array.data.rows;
                let count = array.data && array.data.count;
                if (res && res.length > 0) {
                    res.map(e => {
                        return newArray.push({
                            id: e.id,
                            project: e.project && e.project.id,
                            project_name: e.project && e.project.project_name,
                            reported_by: e.reported_by && e.reported_by.id,
                            reported_by_name: e.reported_by && e.reported_by.display_name,
                            is_jira_issue: e.is_jira_issue,
                            issue_type: e.issue_type && e.issue_type.id,
                            issue_type_name: e.issue_type && e.issue_type.issue_name,
                            issue_type_icon: e.issue_type && e.issue_type.icon_url ? e.issue_type.icon_url : "",
                            status: e.status_id,
                            status_id: e.status_id,
                            status_name: e.status_name,
                            ticket_key: e.ticket_key,
                            summary: e.summary,
                            jira_issue_id: e.jira_issue_id,
                            ticket_jira_key: e.ticket_jira_key,
                            sprint: e.sprint && e.sprint.id ? e.sprint.id : '',
                            sprint_name: e.sprint && e.sprint.sprint_name ? e.sprint.sprint_name : '',
                            story_point: e.story_point,
                            created_on: e.created_on,
                            updated_on: e.updated_on,
                            assignee: e.assignee && e.assignee_id,
                            assignee_name: e.assignee && e.assignee.display_name,
                            priority: e.priority,
                            job_id: e.job_id,
                            job: e.job
                        })
                    })
                }
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.myEditTaskList = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getMYEditTaskList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ////////////task assignee update by id
            .addCase(taskAssigneeUpdateById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(taskAssigneeUpdateById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_taskAssigneeUpdate`;
                state.taskAssigneeUpdate = action.payload;
            })
            .addCase(taskAssigneeUpdateById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //// task staus update by status taskStatusUpdate
            .addCase(taskStatusUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(taskStatusUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_taskStatusData`;
                state.taskStatusData = action.payload;
            })
            .addCase(taskStatusUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //// main project list
            .addCase(main_Project_List.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(main_Project_List.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_mainProjectList`;
                state.mainProjectList = action.payload;
            })
            .addCase(main_Project_List.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            .addCase(allResource.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resource_list`;
                state.resource_list = action.payload;
            })
            .addCase(allIssuetype.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_allIssueList`;
                state.allIssueList = action.payload;
            })

            ////add task column
            .addCase(defaultTaskColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_task`, JSON.stringify(action.payload))
                state.defaultTaskColumns = action.payload
            })

            ////get resource project list
            .addCase(resourceProjectList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resourceProject_list`;
                let array = action.payload
                let resourceArray = []
                if (array && array.data && array.data.rows) {
                    resourceArray = array.data.rows.map((element) => {
                        return element.resource
                    })
                }
                state.resourceProject_list = resourceArray;
            })

            ////get resource wise project list
            .addCase(getResourceWiseProjectList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resourceProject_list`;
                let array = action.payload
                let createProjectArray = [{ value: "create-a-project", label: "Create a project", project_name: "Create New Project", id: "-1" }]
                let projectArray = []
                if (array && array.data && array.data.rows) {
                    array.data.rows.map((element) => {
                        element.project && element.project.status === true && projectArray.push(
                            element.project
                        )
                        return projectArray
                    })
                    array.data.rows.map(e => {
                        e.project && e.project.status === true && createProjectArray.unshift(
                            e.project
                        )
                        return createProjectArray
                    })
                }
                state.resourceWiseProjectList = projectArray;
                state.createNewProjectList = createProjectArray
            })

            ////get task story list
            .addCase(getTaskStoryList.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_taskStoryList`;
            })
            .addCase(getTaskStoryList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_taskStoryList`;
                let array = action.payload
                let storyArray = []
                let uniqueStoryArray = []
                if (array && array.data && array.data.rows) {
                    storyArray = array.data.rows.map((element) => {
                        // if (element.related_stories && element.related_stories.length > 0) {
                        //     storyArray = element.related_stories.map(e => {
                        //         return e
                        //     })
                        // }
                        return element
                    })
                }

                if (storyArray && storyArray.length > 0) {
                    uniqueStoryArray = [...new Map(storyArray.map((item) => [item["id"], item])).values()];
                }
                state.taskStoryList = uniqueStoryArray;
            })

            ///create team member for task 
            .addCase(postTeamMemberCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postTeamMemberCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createTeamMember`;
                state.createTeamMember = action.payload;
            })
            .addCase(postTeamMemberCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update team member for task 
            .addCase(postTeamMemberUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postTeamMemberUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_updateTeamMember`;
                state.updateTeamMember = action.payload;
            })
            .addCase(postTeamMemberUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///create file for task 
            .addCase(postTaskFileCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postTaskFileCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createTaskFile`;
                state.createTaskFile = action.payload;
            })
            .addCase(postTaskFileCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get member list
            .addCase(getMemberList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMemberList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.memberList = action.payload;
            })
            .addCase(getMemberList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get work flow status list
            .addCase(taskWorkFlowStatusList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(taskWorkFlowStatusList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.workFlowStatusList = action.payload;
            })
            .addCase(taskWorkFlowStatusList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get default work flow status list
            .addCase(getDefaultWorkFlowStatusList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDefaultWorkFlowStatusList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.defaultWorkflowStatusList = action.payload;
            })
            .addCase(getDefaultWorkFlowStatusList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get zoom meeting details list
            .addCase(getZoomMeetingDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true
            })
            .addCase(getZoomMeetingDetails.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_zoomMeetingDetailList`;
                state.zoomMeetingDetailList = action.payload;
                state.defaultLoader = false
            })
            .addCase(getZoomMeetingDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoader = false

            })

            ///get call details list
            .addCase(getCallLogDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true

            })
            .addCase(getCallLogDetails.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_callLogDetailList`;
                state.callLogDetailList = action.payload;
                state.defaultLoader = false
            })
            .addCase(getCallLogDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoader = false
            })

            ///get email log details list
            .addCase(getEmailLogsDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true
            })
            .addCase(getEmailLogsDetails.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_emailLogDetailList`;
                state.emailLogDetailList = action.payload;
                state.defaultLoader = false

            })
            .addCase(getEmailLogsDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoader = false

            })

            ///create comment for task
            .addCase(postCommentCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postCommentCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createComment`;
                state.createComment = action.payload;
            })
            .addCase(postCommentCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///epic list by project_id
            .addCase(getEpicListByprojectId.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getEpicListByprojectId.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.epicList = action.payload
            })
            .addCase(getEpicListByprojectId.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///task history list
            .addCase(getTaskHistory.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTaskHistory.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_taskHistoryList`;
                state.taskHistoryList = action.payload
            })
            .addCase(getTaskHistory.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //
            ///TeamMemberListlist
            .addCase(getTeamMemberList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTeamMemberList.fulfilled, (state, action) => {
                state.teamMemberStatus = `${STATUSES.SUCCESS}_teamMemberList`;
                state.teamMemberList = action.payload
            })
            .addCase(getTeamMemberList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get call details by id
            .addCase(getCallLogDetailById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCallLogDetailById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_callDetailById`;
                state.callDetailById = action.payload
            })
            .addCase(getCallLogDetailById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get my job list
            .addCase(getMyJobList.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_myJobList`;
                state.defaultLoaderForMyjoList = true;
            })
            .addCase(getMyJobList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_myJobList`;
                state.defaultLoaderForMyjoList = false;

                let array = action.payload;
                let newArray = [];
                let newArray2 = []
                let res = array.data && array.data.rows;
                let count = array.data && array.data.count;
                if (res && res.length > 0) {
                    res.map(e => {
                        return newArray.push({
                            id: e.id,
                            project: e.project && e.project.id,
                            project_name: e.project && e.project.project_name,
                            reported_by: e.reported_by && e.reported_by.id,
                            reported_by_name: e.reported_by && e.reported_by.display_name,
                            is_jira_issue: e.is_jira_issue,
                            issue_type: e.issue_type && e.issue_type.id,
                            issue_type_name: e.issue_type && e.issue_type.issue_name,
                            issue_type_icon: e.issue_type && e.issue_type.icon_url ? e.issue_type.icon_url : "",
                            status: e.status_id,
                            status_id: e.status_id,
                            status_name: e.status_name,
                            ticket_key: e.ticket_key,
                            summary: e.summary,
                            jira_issue_id: e.jira_issue_id,
                            ticket_jira_key: e.ticket_jira_key,
                            sprint: e.sprint && e.sprint.id ? e.sprint.id : '',
                            sprint_name: e.sprint && e.sprint.sprint_name ? e.sprint.sprint_name : '',
                            story_point: e.story_point,
                            created_on: e.created_on,
                            updated_on: e.updated_on,
                            assignee: e.assignee && e.assignee_id,
                            assignee_name: e.assignee && e.assignee.display_name,
                            priority: e.priority,
                            job_id: e.job_id,
                            job: e.job

                        })
                    })
                }
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.myJobList = newArray2 && newArray2.data ? newArray2 : action.payload;
                //state.myJobList = action.payload
            })
            .addCase(getMyJobList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoaderForMyjoList = false;

            })

            //get upload file list
            .addCase(getFileUploadList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true
            })
            .addCase(getFileUploadList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_uploadFileList`;
                state.defaultLoader = false
                let uploadedFiles = action.payload;
                // let newArray = [];
                // let newArray2 = []
                // let res = array.data && array.data.rows;
                // let count = array.data && array.data.count;
                // if (res && res.length > 0) {
                //     res.map(e => {
                //         if (e.status === 1 || e.status === 2 || e.status === 4 || e.status === 5) { newArray.push(e) }
                //         return newArray
                //     })
                // }

                // newArray2 = { "data": { "rows": newArray, "count": count } }

                state.uploadFileList = uploadedFiles;
            })
            .addCase(getFileUploadList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error;
                state.defaultLoader = false
            })

            ///create Reminder for task
            .addCase(createRemindStory.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createRemindStory.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createReminder`;
                state.createReminder = action.payload;
            })
            .addCase(createRemindStory.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get list remainder
            .addCase(getRemainderList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRemainderList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_remainderList`;
                state.remainderList = action.payload;
            })
            .addCase(getRemainderList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update remainder status
            .addCase(remainderUpdateById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(remainderUpdateById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_remainderUpdate`;
                state.remainderUpdate = action.payload;
            })
            .addCase(remainderUpdateById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///for epic task 
            //////////////my task by id
            .addCase(getMYEpicTaskList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true
            })
            .addCase(getMYEpicTaskList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                let array = action.payload;
                state.defaultLoader = false
                let newArray = [];
                let newArray2 = []
                let res = array.data && array.data.rows;
                let count = array.data && array.data.count;
                if (res && res.length > 0) {
                    res.map(e => {
                        return newArray.push({
                            id: e.id,
                            project: e.project && e.project.id,
                            project_name: e.project && e.project.project_name,
                            reported_by: e.reported_by && e.reported_by.id,
                            reported_by_name: e.reported_by && e.reported_by.display_name,
                            is_jira_issue: e.is_jira_issue,
                            issue_type: e.issue_type && e.issue_type.id,
                            issue_type_name: e.issue_type && e.issue_type.issue_name,
                            issue_type_icon: e.issue_type && e.issue_type.icon_url ? e.issue_type.icon_url : "",
                            status: e.status_id,
                            status_id: e.status_id,
                            status_name: e.status_name,
                            ticket_key: e.ticket_key,
                            summary: e.summary,
                            jira_issue_id: e.jira_issue_id,
                            ticket_jira_key: e.ticket_jira_key,
                            sprint: e.sprint && e.sprint.id ? e.sprint.id : '',
                            sprint_name: e.sprint && e.sprint.sprint_name ? e.sprint.sprint_name : '',
                            story_point: e.story_point,
                            created_on: e.created_on,
                            updated_on: e.updated_on,
                            assignee: e.assignee && e.assignee_id,
                            assignee_name: e.assignee && e.assignee.display_name,
                            priority: e.priority,
                            job_id: e.job_id,
                            job: e.job
                        })
                    })
                }
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.myEpicTaskList = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getMYEpicTaskList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoader = false
            })

            ////new m task list for loader
            //////////////my task by id
            .addCase(getMYTaskLists.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true
            })
            .addCase(getMYTaskLists.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                state.defaultLoader = false
                let array = action.payload;
                let newArray = [];
                let newArray2 = []
                let res = array.data && array.data.rows;
                let count = array.data && array.data.count;
                if (res && res.length > 0) {
                    res.map(e => {
                        return newArray.push({
                            id: e.id,
                            project: e.project && e.project.id,
                            project_name: e.project && e.project.project_name,
                            reported_by: e.reported_by && e.reported_by.id,
                            reported_by_name: e.reported_by && e.reported_by.display_name,
                            is_jira_issue: e.is_jira_issue,
                            issue_type: e.issue_type && e.issue_type.id,
                            issue_type_name: e.issue_type && e.issue_type.issue_name,
                            issue_type_icon: e.issue_type && e.issue_type.icon_url ? e.issue_type.icon_url : "",
                            status: e.status_id,
                            status_name: e.status_name,
                            ticket_key: e.ticket_key,
                            summary: e.summary,
                            jira_issue_id: e.jira_issue_id,
                            ticket_jira_key: e.ticket_jira_key,
                            sprint: e.sprint && e.sprint.id ? e.sprint.id : '',
                            sprint_name: e.sprint && e.sprint.sprint_name ? e.sprint.sprint_name : '',
                            story_point: e.story_point,
                            created_on: e.created_on,
                            updated_on: e.updated_on,
                            assignee: e.assignee && e.assignee.id,
                            assignee_name: e.assignee && e.assignee.display_name,
                            priority: e.priority,
                            job_id: e.job_id,
                            job: e.job,
                            candidate_phone: e.candidate_phone,
                        })
                    })
                }
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.myTaskLists = newArray2 && newArray2.data ? newArray2 : action.payload;

            })
            .addCase(getMYTaskLists.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoader = false

            })

            ///get project workflow  status list
            .addCase(getProjectWorkflowStatus.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getProjectWorkflowStatus.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_projectWorkflowStatusList`;
                state.projectWorkflowStatusList = action.payload;
                state.projectWorkflowStatusList_status = `${STATUSES.SUCCESS}_projectWorkflowStatusList`;

            })
            .addCase(getProjectWorkflowStatus.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

             ///get epic to story list details by ids
             .addCase(getMYEpicTaskListByIDs.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMYEpicTaskListByIDs.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_myEpicTaskListByIDs`;
                state.myEpicTaskListByIDs = action.payload;
            })
            .addCase(getMYEpicTaskListByIDs.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            
            


    },
});

export default taskSlice.reducer;