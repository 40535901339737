import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./sprint.scss"
import { toast, ToastContainer } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { FormControl, TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sprintColumn } from "../../components/datatable-coulms/tableCoulmns";
import { useEffect } from "react";
import { getSprintList, getSprintListByJira } from "../../redux/features/sprintSlice";
import Sync from "@mui/icons-material/Sync";
import { useState } from "react";
import { project_List } from "../../redux/features/taskSlice";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import Select from 'react-select';
import TablePaginationActions from "../../components/TablePaginationActions";
import { Tooltip } from "@mui/material";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";

const getUnique = (arr, index) => {

    const unique = arr
        .map(e => e[index])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

    return unique;
}

const SprintList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [projectId, setProjectId] = useState(0)
    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":25,"order_by":{"column":"-id"}, "coulmns":[${DefaultFilterColumns.SPRINT_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [loader, setLoader] = useState(false)
    const { sprintList, error, status, syncSprintList } = useSelector(state => state.sprint)
    const { projectList } = useSelector(state => state.task)
    const [projectWiseList, setProjectWiseList] = useState(false)

    useEffect(() => {
        dispatch(getSprintList(filterQuery))
        dispatch(project_List())
    }, [])

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
        }
    }, [error])

    if (projectWiseList === false && projectList && projectList.status) {
        let array = []
        if (projectList.status === 200) {
            projectList && projectList.data && projectList.data.rows.map((option) => {
                return array.push({ label: option.project.project_name, value: option.project.id })
            })
        }
        array.length > 0 ? setProjectWiseList(getUnique(array, 'value')) : setProjectWiseList(array)
    }

    const handleChange = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            setProjectId({ [name]: value });
        } else {
            setProjectId(0)
        }
    }
    const syncSprintByProject = () => {
        setLoader(true)
        dispatch(getSprintListByJira(projectId['project']))
    }

    if (loader === true && status === STATUSES.SUCCESS && syncSprintList && syncSprintList.message) {
        setLoader(false)
        if (syncSprintList.message === 'Success') {
            // toast("Sync Data Successfully !!!");
            toast.success(syncSprintList.message)
            dispatch(getSprintList(filterQuery))
        } else {
            toast(syncSprintList.message)
        }
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getSprintList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getSprintList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(sprintList && sprintList.data && sprintList.data.rows && sprintList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                            filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "coulmns":[${DefaultFilterColumns.SPRINT_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getSprintList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "coulmns":[${DefaultFilterColumns.SPRINT_LIST_COLUMNS}]}`)
            dispatch(getSprintList(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "coulmns":[${DefaultFilterColumns.SPRINT_LIST_COLUMNS}]}`))
        }
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getSprintList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" }, "coulmns":[${DefaultFilterColumns.SPRINT_LIST_COLUMNS}] }`)
        }
    }

    return (
        <div className="sprint">
            <Sidebar />
            <div className="sprintContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Sprint List</h3>
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 150 }}>

                                <Select
                                    className="task-dropdown"
                                    id="demo-customized-select-native"
                                    classNamePrefix="select"
                                    placeholder="Select Project"
                                    defaultValue={projectId}
                                    isSearchable={true}
                                    isClearable={true}
                                    name={"project"}
                                    options={
                                        projectWiseList === false ? [] : projectWiseList.map((option) => {
                                            return { label: option.label, value: option.value, name: "project" }
                                        })
                                    }
                                    onChange={handleChange}
                                />
                            </FormControl>

                            {projectId && (projectId['project'] === '0' || projectId === 0) ?

                                <Tooltip title="Sprint">
                                    <button style={{ backgroundColor: 'rgb(117, 117, 117)', border: '1px solid rgb(117, 117, 117)', marginTop: 10 }} disabled className="btn-add-new ">
                                        <Sync />
                                    </button>
                                </Tooltip>
                                :
                                <Tooltip title="Sprint">
                                    <button style={{ marginTop: 10 }} onClick={() => syncSprintByProject()} className="btn-add-new ">
                                        <Sync />
                                    </button>
                                </Tooltip>
                            }
                            <RefreshButton api={getSprintList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    {(loader === true || status === STATUSES.LOADING) && <LoaderForm />}
                    <ToastContainer />
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={sprintList && sprintList.data && sprintList.data.rows ? sprintList.data.rows : []}
                        columns={sprintColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>

        </div>
    )
}

export default SprintList