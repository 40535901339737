import { Add, ArrowBack } from '@mui/icons-material'
import { Stack, Switch, TablePagination, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import LoaderForm from '../../../components/common-function/LoaderForm'
import Navbar from '../../../components/navbar/Navbar'
import Sidebar from '../../../components/sidebar/Sidebar'
import TablePaginationActions from '../../../components/TablePaginationActions'
import { STATUSES } from '../../../redux/common-status/constant'
import { getSovernCredentials, updateSovernCredential } from '../../../redux/features/sovernCredentialSlice'
import moment from 'moment'
import CustomNoRowsOverlay from '../../../components/CustomEmptyOverlay'
import { RefreshButton } from '../../../components/common-function/RefreshButton'
import { DefaultFilterColumns } from '../../../components/common-function/DefaultFilterColumns'

export default function SovernCredentials() {

    const dispatch = useDispatch();
    const { sovernCredentialsList, status, update_sovern_credential, error } = useSelector(state => state.sovernCredentialSlice)
    const { data: list } = sovernCredentialsList

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.SOVERN_CREDENTIAL_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: true });
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(getSovernCredentials(filterQuery))
    }, [])

    if (reload === true && update_sovern_credential && update_sovern_credential.status) {
        setReload(false)
        if (update_sovern_credential.status === 200) {
            toast.success(update_sovern_credential.message)
            dispatch(getSovernCredentials(filterQuery))
        } else {
            toast.error(update_sovern_credential.message)
        }
    }
    const changeStatus = (status, data) => {
        const ID = data && data.id
        let formValues;
        if (status === true) {
            formValues = { ...formValues, account_id: data.account_id, is_active: false }
        } else {
            formValues = { ...formValues, account_id: data.account_id, is_active: true }
        }
        dispatch(updateSovernCredential({ ID, formValues }))
        setReload(true)
    }

    if (reload === true && status === STATUSES.FAILURE && error && error.message) {
        setReload(false)
        toast.error(error.message);
    }

    const sovernCredentialColumn = [
        // { field: "id", headerName: "ID", width: 70, filterable: false, },
        { field: "id", headerName: "SN", width: 90 },
        { field: "name", headerName: "Account Name", width: 220 },
        {
            field: "account_id",
            headerName: "Account Id",
            width: 220,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.account_id ? params.row.account_id : "-"}
                    </div>
                );
            },
        },
        { field: "total_credits", headerName: "Total Credits", width: 200 },
        { field: "credit_used", headerName: " Credit Used", width: 140 },
        { field: "credit_remaning", headerName: "Remaning Credits", width: 195 },
        {
            field: "expiration_date",
            headerName: "Expiration Date",
            // type: 'date',
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.expiration_date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        {
            field: "is_active",
            headerName: "Status",
            width: 300,
            renderCell: (params) => {
                return (
                    <div>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography className={`cellWithStatus ${params.row.is_active}`}>
                                {params.row.is_active === true ? "Active" : "Inactive"}
                            </Typography>
                            <Switch
                                checked={params.row.is_active === true ? true : false}
                                inputProps={{ 'aria-label': 'ant design' }}
                                onChange={() => changeStatus(params.row.is_active, params.row)}
                                disabled={params.row.credit_remaning > 0 ? false : true}
                            />
                        </Stack>
                    </div >
                );
            },
        },
    ];

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getSovernCredentials(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getSovernCredentials(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.SOVERN_CREDENTIAL_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getSovernCredentials(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            // setPageSize(10)
            setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.SOVERN_CREDENTIAL_LIST_COLUMNS}]}`)
            dispatch(getSovernCredentials(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.SOVERN_CREDENTIAL_LIST_COLUMNS}]}`))
        }
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getSovernCredentials(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": ${pageSize}, "limit": ${limit}, "order_by": { "column": "-id" }, "columns":[${DefaultFilterColumns.SOVERN_CREDENTIAL_LIST_COLUMNS}] }`)
        }
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>All Sovern Credential</h3>
                        <div>
                            <Tooltip title="Add New Sovern credential">
                                <Link to={"/add-sovern-credential"} className="btn-add-new">
                                    <Add />
                                </Link>
                            </Tooltip>
                            <RefreshButton api={getSovernCredentials} filter={filterQuery} />

                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new">
                                    <ArrowBack />
                                </Link>
                            </Tooltip>
                        </div>
                    </div>

                    {(status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    <DataGrid
                        rowHeight={54}
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={list && list.rows ? list.rows : []}
                        columns={sovernCredentialColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>
        </div >
    )
}
