import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { taskViewById } from "../../redux/features/taskSlice";
import { Tooltip, Button } from "@mui/material";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import { resourceListForTaskEdit } from "../../redux/features/resourceSlice";
import { Close, Download, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import TaskBreadCrumb from "../../components/breadcrumb/TaskBreadCrumb";
// import { ContentParser } from "./ContentParser";
import FileViewer from "react-file-viewer";
import "./task.scss"
const domain_data = LocalStorageDataService.get_domain_data()
const { REACT_APP_API_BASEURL } = process.env;


export const StoryDetails = ({ epicId, hideDiv, activeDivs }) => {
    const dispatch = useDispatch();
    const [apiLoading, setApiLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [attachments, setAttachments] = useState([])
    const [descriptionType, setDescriptionType] = useState("")
    const { status, taskData, myEpicTaskListByIDs } = useSelector((state) => state.task);
    const [show] = useState(false);
    const [nextId, setNextId] = useState(epicId)
    const [prevId, setPrevId] = useState("")


    useEffect(() => {
        if (epicId && activeDivs === `story_${epicId}`) {
            setApiLoading(true);
            dispatch(taskViewById(epicId));
        }
        dispatch(resourceListForTaskEdit());
        setAttachments([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDivs]);

    const nextStep = (next) => {
        let array = myEpicTaskListByIDs.data && myEpicTaskListByIDs.data.rows ? myEpicTaskListByIDs.data.rows : []
        let finalArray = array.map(function (obj) {
            return obj.id;
        });
        let nextIndex = []
        let prevIndex = []

        finalArray.map(obj => {
            if (obj < next) {
                nextIndex.push(obj)
            } else {
                prevIndex.push(obj)
            }

        });
        if (prevIndex && prevIndex.length > 0) {
            setPrevId(prevIndex[prevIndex.length - 1])
        }
        if (nextIndex && nextIndex.length > 0 && nextIndex[0]) {
            //setPrevId(next)
            //prevIndex && prevIndex.length > 0 && setPrevId(prevIndex[prevIndex.length - 1])
            if (nextIndex.length === 1) {
                setNextId("")
            } else {
                setNextId(nextIndex[0])
            }
            setApiLoading(true);
            dispatch(taskViewById(nextIndex[0]));
            dispatch(resourceListForTaskEdit());
            setAttachments([])
        } else {
            setNextId("")

        }
    }

    const previousStep = (prev) => {
        let array = myEpicTaskListByIDs.data && myEpicTaskListByIDs.data.rows ? myEpicTaskListByIDs.data.rows : []
        let finalArray = array.map(function (obj) {
            return obj.id;
        });
        let prevIndex = []
        let nextIndex = []
        finalArray.map(obj => {
            if (obj > prev) {
                prevIndex.push(obj)
            } else {
                nextIndex.push(obj)
            }


        });
        setNextId(prev)
        if (prevIndex && prevIndex.length > 0) {
            let length = prevIndex.length - 1
            let indexId = prevIndex[length]
            if (length === -1) {
                setPrevId("")
            } else {
                setPrevId(indexId)
            }
        } else {
            setPrevId("")

        }
        if (prev) {
            setApiLoading(true);
            dispatch(taskViewById(prev));
            dispatch(resourceListForTaskEdit());
            setAttachments([])
        }

    }

    //download file 
    const fileDownload = (url) => {
        let ApIURL = REACT_APP_API_BASEURL + url;
        window.open(ApIURL.replace("api//", "api/"));
    };

    if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskData`) {
        let data = taskData.data;
        if (taskData.status === 400) {
            toast.error(taskData.message);
        }
        setApiLoading(false);
        setFormValues(data);
        if (data && data.attachments && data.attachments.length > 0) {

            if (attachments.length === 0) {
                let attachmentIs = []
                data.attachments.map(e => {
                    let fileIs = e.file_name.split(".")
                    let lengthIs = fileIs.length - 1
                    return attachmentIs.push({ uri: REACT_APP_API_BASEURL.replace("api/", "api") + e.api_url, type: fileIs[lengthIs] })
                })
                setAttachments(attachmentIs)
            }

        }
        // let descriptionTypes = data && data.description && (data.description).match("{") ? ContentParser(JSON.parse(data.description)) : data && data.description
        let descriptionTypes = data && data.description

        setDescriptionType(descriptionTypes)

    }
    return (
        <div className="ss-datatable style-table" style={{ marginTop: '20px' }}>
            <ToastContainer />

            <div className="row">


                <div className="col ss-top-icon" style={{ textAlign: "end" }}>
                    {/* <div style={{ textAlign: "center" }}> */}
                    {prevId > 0 &&
                        <Tooltip title="Previous">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "blue",
                                    color: "#fff"
                                }}
                                onClick={() => previousStep(prevId)} >
                                <KeyboardArrowLeft /></Button>

                        </Tooltip>
                    }
                    {nextId > 0 && myEpicTaskListByIDs.data && myEpicTaskListByIDs.data.rows && myEpicTaskListByIDs.data.rows.length > 1 &&
                        <Tooltip title="Next">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "blue",
                                    color: "#fff"
                                }}
                                onClick={() => nextStep(nextId)}
                            >
                                <KeyboardArrowRight />
                            </Button>

                        </Tooltip>
                    }
                    {/* </div>
                    <div style={{ textAlign: "end" }}> */}
                    <Tooltip title="Close">
                        <Button
                            style={{
                                marginTop: "3px",
                                backgroundColor: "rgb(201 201 201)",
                                color: "rgb(110 110 110)"
                            }}
                            onClick={() => hideDiv(false)}
                        >
                            <Close />
                        </Button>
                    </Tooltip>
                    {/* </div> */}
                </div>


            </div>
            <div className="top-headings">
                {/* <h3>Story Task</h3> */}
                <div>
                    {formValues &&
                        formValues.project &&
                        formValues.project.project_name ? (
                        <TaskBreadCrumb formValues={formValues} />
                    ) : (
                        <p>Project</p>
                    )}

                    <button style={{ borderWidth: 0, backgroundColor: "#fff" }}>
                        <h3 className="story-epic-summary">
                            {formValues && formValues.summary}
                        </h3>
                    </button>
                </div>
                {apiLoading === true && <LoaderForm />}
            </div>

            <div className="edit-details-row ss-detail-row">

                <div
                    className={`edit-details-col-left ${show ? "show-hide-left-side-colum2" : ""
                        }`}
                >
                    <div className="left-description">
                        {domain_data === true && formValues && formValues.show_resume === true ? '' : <h6>Description</h6>}
                        {domain_data === false ?
                            <p style={{ marginBottom: 20 }}>
                                {" "}
                                {formValues && formValues.description ? <span dangerouslySetInnerHTML={{ __html: formValues && formValues.description }} /> : ""}
                            </p>
                            :
                            formValues && formValues.show_resume === true ?
                                <p style={{ marginBottom: 20 }}>

                                    {attachments.length > 0 &&
                                        attachments.map(e => {
                                            return (
                                                e.type === 'doc' ?
                                                    <iframe class="google-docs iframe" src={`https://docs.google.com/viewer?url=${e.uri}&embedded=true`} frameborder="0"
                                                        scrolling="no" height="0" width="0" role="document" aria-label="PDF document" title="PDF document" style={{ borderStyle: 'none', width: '100%', height: '800px' }}></iframe>
                                                    :
                                                    <FileViewer fileType={e.type} filePath={e.uri} />
                                            )
                                        })

                                    }

                                </p>
                                :
                                formValues && formValues.description &&
                                <p style={{ marginBottom: 20 }}>
                                    {" "}
                                    <span dangerouslySetInnerHTML={{ __html: formValues && descriptionType }} />
                                </p>
                        }
                        {/* {formValues?.description ? <RichTextEditor data={JSON.parse(formValues.description )} /> : ""} */}
                    </div>

                    {formValues && formValues.assignee_id ===
                        LocalStorageDataService.userDetail().id && (
                            <h4 style={{ display: "flex" }}>
                                Attachments(
                                {formValues &&
                                    formValues.attachments &&
                                    formValues.attachments.length}
                                )

                            </h4>
                        )}
                    {formValues && formValues.assignee_id ===
                        LocalStorageDataService.userDetail().id && (
                            <div className="attchment-all-top-list">
                                {formValues &&
                                    formValues.attachments &&
                                    formValues.attachments.length > 0 ? (
                                    formValues.attachments.map((item, i) => {
                                        return (
                                            <div key={i} className="attchment-name-image">
                                                <Tooltip title={item.file_name}>
                                                    <div className="attchment-file-name">
                                                        {item.file_name}
                                                    </div>
                                                </Tooltip>
                                                <div className="action-attechment-btn">
                                                    <Tooltip title="Download">
                                                        <Download
                                                            className="download-icon"
                                                            onClick={() => fileDownload(item.api_url)}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p>No Attachment</p>
                                )}
                            </div>
                        )}
                </div>
            </div>
        </div >
    );
};
