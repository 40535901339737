import "./report.scss"
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { FormControl, TablePagination, Tooltip } from "@mui/material"
import { ArrowBack, Close } from "@mui/icons-material"
import { toast, ToastContainer } from "react-toastify"
import { DataGrid } from "@mui/x-data-grid"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import LoaderForm from "../../components/common-function/LoaderForm"
import moment from "moment"
import TablePaginationActions from "../../components/TablePaginationActions"
import Select from "react-select";
import { DateRangePicker } from 'rsuite';
import ShareModule from "../../components/common-function/ShareModule"
import { getResumeAnalysisReport } from "../../redux/features/resumeAnalysisReportSlice"
import { resourceListOption, skilOption } from "../../redux/features/optionsSlice"
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay"
import CustomeTooltip from "../../components/common-function/CustomeTooltip"
import GetLocalstorage from "../../components/common-function/GetLocalstorage"
import UcActionComponent from "../../components/common-function/UcActionComponent"
import ResumeEdit from "../resume/ResumeEdit"
import { RefreshButton } from "../../components/common-function/RefreshButton"

const resumeStatus = GetLocalstorage.get_file_status();
export default function ResumeAnalysisReport({ type }) {

    const { REACT_APP_API_BASEURL } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { resumeAnalysisReportList, status, error } = useSelector(state => state.resumeAnalysisReportSlice)
    const { resources, skils } = useSelector(state => state.dropdownOptions)

    const { data: list } = resumeAnalysisReportList
    let current_date_filter = { "value": [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], "operation": "range" }

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState({ "filter": { "uploaded_date": current_date_filter }, "skip": 0, "limit": 25 })
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [loading, setLoading] = useState(true)
    const [downliadFile, setDownloadFile] = useState(false)

    //filter states
    const [date, setDate] = useState([new Date(), new Date()])
    const [selectUploadedBy, setSelectUploadedBy] = useState("")
    const [selectUploadStatus, setSelectUploadStatus] = useState("")
    const [selectSkill, setSelectSkill] = useState("")
    const [experience, setExperience] = useState("")

    //share page form
    const [openSingle, setOpenSingle] = useState(false)
    // resume edit states
    const [resumeEditID, setResumeEditId] = useState('')
    const [openResumeEdit, setOpenResumeEdit] = useState(false)

    useEffect(() => {
        dispatch(resourceListOption())
        dispatch(skilOption())
    }, [])


    useEffect(() => {
        dispatch(getResumeAnalysisReport(filterQuery))
    }, [filterQuery])

    if (loading === true && resumeAnalysisReportList) {
        if (resumeAnalysisReportList.status === 200) {
        } else {
            toast.error(resumeAnalysisReportList.message)
        }
        setLoading(false)
    }

    const search = () => {
        let array = []
        selectSkill && selectSkill.length > 0 && selectSkill.map((o) => array.push(o.value));
        let filterCondition = { filter: {} }
        let date_filter = date && { "value": [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')], "operation": "range" }
        // condition for filter
        if (date && date.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "uploaded_date": date_filter,
                }
            }
        }
        if (selectUploadedBy) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "uploaded_by": { "value": `${selectUploadedBy.value}`, "operation": "eq" },
                }
            }
        }
        if (selectUploadStatus) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "upload_status": { "value": `${selectUploadStatus.value}`, "operation": "eq" },
                }
            }
        }
        if (experience) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "experience": { "value": `${experience}`, "operation": "eq" },
                }
            }
        }
        if (selectSkill && selectSkill.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "skill_id": { "value": array, "operation": "in" }
                }
            }
        }
        filterCondition['skip'] = 0
        filterCondition['limit'] = limit
        // filterCondition['order_by'] = { "column": '-uploaded_date' }
        setLoading(true)
        setFilterQuery(filterCondition)

    }

    const clear = () => {
        setDate([new Date(), new Date()])
        setSelectUploadedBy("")
        setSelectUploadStatus("")
        setExperience("")
        setSelectSkill("")
        setLoading(true)
        setFilterQuery({ "filter": { "uploaded_date": current_date_filter }, "skip": 0, "limit": limit })
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
        delete filterQuery['for_download']
        delete filterQuery['no_limit']
        let newFilterData = filterQuery
        setFilterQuery(newFilterData)
        dispatch(getResumeAnalysisReport(newFilterData))
    }

    if (downliadFile === true && resumeAnalysisReportList && resumeAnalysisReportList.data) {
        let api_url = resumeAnalysisReportList && resumeAnalysisReportList.data && resumeAnalysisReportList.data.download_api_url;
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
            }
        }
    }

    const downloadCsvFile = () => {
        setDownloadFile(true)
        let newFilter = filterQuery
        delete newFilter['skip']
        delete newFilter['limit']
        newFilter['for_download'] = "True"
        newFilter['no_limit'] = "True"
        dispatch(getResumeAnalysisReport(newFilter))
    }

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
            setDownloadFile(false)
        }
    }, [error])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = filterQuery
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery({ ...filterQuery, ...newFilterData })
        // dispatch(getResumeAnalysisReport(newFilterData))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = filterQuery
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery({ ...filterQuery, ...newFilterData })
        // dispatch(getResumeAnalysisReport(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    //custom filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue

            let filterData;
            if (filterModel.items[0].columnField === 'candidate_info') {
                filterData = {
                    "or": {
                        'candidate_name': { "value": filterModel.items[0].value, "operation": operatorValue },
                        'phone_number': { "value": filterModel.items[0].value, "operation": operatorValue },
                        'email': { "value": filterModel.items[0].value, "operation": operatorValue },
                    },
                    "filter": {},
                    "skip": 0, "limit": limit
                }
            } else {
                filterData = { "filter": { [filterModel.items[0].columnField]: { "value": filterModel.items[0].value, "operation": operatorValue } }, "skip": 0, "limit": limit }
            }

            setFilterQuery(filterData)
            setCurrentPage(0)
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setPageSize(10)
            setFilterQuery({ "filter": { "uploaded_date": current_date_filter }, "skip": 0, "limit": limit })
        }
    }

    //custom sort
    const handleSort = (sort) => {
        if (sort.length) {
            setFilterQuery({
                ...filterQuery,
                "skip": 0, "limit": 10,
                order_by: {
                    "column": sort[0].sort === "desc" ?
                        `-${sort[0].field === "uploaded_by_name" ? "uploaded_by" : sort[0].field}` :
                        (sort[0].field === "uploaded_by_name") ? "uploaded_by" : sort[0].field
                }
            })
        } else {
            setFilterQuery({ "filter": { "uploaded_date": current_date_filter }, "skip": 0, "limit": limit })
        }
    }

    const updateDate = (evt) => {
        setDate(evt)
    }

    const handleClickEdit = (data) => {
        setOpenResumeEdit(true)
        setResumeEditId(data.uploaded_id)
    }

    // columns
    const resumeAnalysisReportColumns = [
        { field: 'id', headerName: 'ID', width: 10, filterable: false },
        { field: 'uploaded_date', headerName: 'Date Of Upload', width: 175, filterable: false },
        { field: "uploaded_by_name", headerName: "Uploaded By", width: 190, filterable: false },
        {
            field: 'candidate_info',
            headerName: 'Candidate Information',
            width: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.candidate_info ?
                            <div>
                                {params.row.candidate_info.split(",").map((e, index) => {
                                    return (
                                        <p key={index} style={{ margin: 0 }}>
                                            {e}<br />
                                        </p>
                                    )
                                })
                                }
                            </div>
                            : ""
                        }
                    </div >
                )
            }
        },
        {
            field: 'skill_name',
            headerName: 'Skills With Experience Duration',
            width: 340,
            sortable: false,
            renderCell: (params) => {
                let arr = params.row.skill_name && params.row.skill_name.split(",")
                let data = arr && arr.length > 0 && arr.map((item, index) => {
                    return (<div> {index + 1 + ")" + item} </div>)
                })
                return (
                    <div className="cellWithSkillName">
                        {params.row.skill_name ?
                            <CustomeTooltip title={data} data={params.row.skill_name} />
                            : "-"
                        }
                    </div>
                );
            },
        },
        {
            field: 'work_experience_in_month',
            headerName: 'Total Work Experience',
            width: 290,
            sortable: false,
            filterable: false,
        },
        {
            field: 'company_name',
            headerName: 'Companies Worked For',
            width: 220,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithCompaniesName">
                        {params.row.company_name ?
                            <CustomeTooltip title={params.row.company_name} data={params.row.company_name} />
                            : "-"
                        }
                    </div>
                );
            },
        },
        { field: 'story', headerName: 'Story Ids', width: 200, sortable: false, filterable: false },
        { field: 'epic', headerName: 'Epic Ids', width: 200, sortable: false, filterable: false },
        {
            field: 'upload_status',
            headerName: 'Current Resume Status',
            width: 260,
            sortable: false,
            type: 'singleSelect',
            valueOptions: resumeStatus,
            renderCell: (params) => {
                return (
                    <div className="cellAction" style={params.row.upload_status === 0 ? { color: 'green' } :
                        params.row.upload_status === 1 ? { color: 'DeepSkyBlue' } :
                            params.row.upload_status === 2 ? { color: 'DarkGreen' } :
                                params.row.upload_status === 3 ? { color: 'GreenYellow' } :
                                    params.row.upload_status === 4 ? { color: 'DarkRed' } :
                                        params.row.upload_status === 5 ? { color: 'Gold' } :
                                            params.row.upload_status === 6 ? { color: 'Red' } :
                                                params.row.upload_status === 9 ? { color: 'DarkKhaki' } :
                                                    params.row.upload_status === 900 ? { color: 'DarkCyan' } :

                                                        { color: '#000000' }
                    }>
                        <p>{params.row.upload_status === 0 ? "Uploaded Successfully" :
                            params.row.upload_status === 1 ? "Parsing In Progress" :
                                params.row.upload_status === 2 ? "Parsed Successfully" :
                                    params.row.upload_status === 3 ? "Story Created" :
                                        params.row.upload_status === 4 ? "Invalid File Uploaded" :
                                            params.row.upload_status === 5 ? "Story Creation In Progress" :
                                                params.row.upload_status === 6 ? "Story Creation Failed" :
                                                    params.row.upload_status === 9 ? "Story Created But Contact Info Is Pending" :
                                                        params.row.upload_status === 900 ? "Story Already Created For Resume In Same Epic" :
                                                            "-"}</p>
                    </div>
                )
            }
        },
        {
            field: "action",
            headerName: "Action",
            width: "100",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            moduleName="report-master"
                            rowid={params.row}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={handleClickEdit}
                            viewButton={false}
                        />
                    </div>
                );
            },
        },
    ]

    //Close Sharepage Modal
    const onCloseModal = () => {
        setOpenSingle(false)
    }

    let i = 0
    return (
        <div className="report">
            {type !== 'tab' && <Sidebar />}
            <div className="reportContainer">
                {type !== 'tab' && <Navbar />}
                <div className="datatable">
                    {type !== 'tab'
                        && <div className="top-headings">
                            <h3>Resume Analysis Report</h3>
                            <div>
                                <Tooltip title="Back">
                                    <button className='btn-add-new' onClick={() => { openResumeEdit === false ? navigate(-1) : setOpenResumeEdit(false) }}>{openResumeEdit === false ? <ArrowBack /> : <Close />}</button>
                                </Tooltip>
                                <RefreshButton api={getResumeAnalysisReport} filter={filterQuery} style={{ marginTop: 0 }} />

                            </div>

                        </div>}

                    {openResumeEdit === false ?
                        <>
                            <div className="advance-search-input">
                                <div style={{ flexBasis: "32%", }}>
                                    <FormControl style={{ width: "100%" }}>
                                        <DateRangePicker
                                            format="dd-MM-yyyy"
                                            placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
                                            showMeridian
                                            value={date}
                                            onChange={(evt) => updateDate(evt)}
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ flexBasis: "33%", }}>
                                    <FormControl style={{ width: "100%" }}>
                                        <Select
                                            placeholder="Uploaded By"
                                            isClearable
                                            value={selectUploadedBy}
                                            onChange={(evt) => { setSelectUploadedBy(evt) }}
                                            options={resources ? resources.map(e => { return { value: e.id, label: e.display_name + "(" + e.email + ")" } })
                                                : []
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ flexBasis: "33%", }}>
                                    <FormControl style={{ width: "100%" }}>
                                        <Select
                                            placeholder="Select Current Resume Status"
                                            isClearable
                                            value={selectUploadStatus}
                                            onChange={(evt) => { setSelectUploadStatus(evt) }}
                                            options={resumeStatus ? resumeStatus.map(e => { return { value: e.value, label: e.label } })
                                                : []
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>

                            <div className="advance-search-input">
                                <div>
                                    {/* <FormControl style={{ width: "350px" }}>
                                <input
                                    placeholder="Enter experience"
                                    type="number"
                                    min={0}
                                    className="custom-input-box-field"
                                    value={experience}
                                    onChange={(evt) => { setExperience(evt.target.value) }}
                                />
                            </FormControl> */}

                                    <FormControl style={{ width: "368px" }}>
                                        <Select
                                            isMulti
                                            placeholder="Select Skills"
                                            isClearable
                                            value={selectSkill}
                                            onChange={(evt) => { setSelectSkill(evt) }}
                                            options={skils ? skils.map(e => { return { value: e.id, label: e.skill_name } })
                                                : []
                                            }
                                        />
                                    </FormControl>
                                </div>

                                <div className="search-btn-section-class">
                                    <button
                                        disabled={status === `${STATUSES.LOADING}_resumeAnalysisReportList`}
                                        className="filter-btn-primary"
                                        onClick={() => search()}
                                        style={status === `${STATUSES.LOADING}_resumeAnalysisReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                        Search
                                    </button>
                                    <button
                                        disabled={status === `${STATUSES.LOADING}_resumeAnalysisReportList`}
                                        className="filter-btn-primary"
                                        onClick={() => clear()}
                                        style={status === `${STATUSES.LOADING}_resumeAnalysisReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                        Clear
                                    </button>
                                    <button
                                        disabled={status === `${STATUSES.LOADING}_resumeAnalysisReportList`}
                                        className="filter-btn-primary"
                                        onClick={() => setOpenSingle(true)}
                                        style={status === `${STATUSES.LOADING}_resumeAnalysisReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                        Share
                                    </button>
                                    <button
                                        disabled={status === `${STATUSES.LOADING}_resumeAnalysisReportList`}
                                        className="filter-btn-primary"
                                        onClick={() => downloadCsvFile()}
                                        style={status === `${STATUSES.LOADING}_resumeAnalysisReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                        Download as CSV
                                    </button>
                                </div>
                            </div>

                            <ToastContainer />
                            {(loading === true || status === `${STATUSES.LOADING}_resumeAnalysisReportList`) && <LoaderForm />}

                            <DataGrid
                                className="datagrid"
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    setColumnVisibilityModel(newModel)
                                }
                                rows={list && list.rows ? list.rows : []}
                                columns={resumeAnalysisReportColumns}
                                pageSize={pageSize}
                                style={{ fontSize: 16 }}
                                getRowId={(row) => row + i++}
                                onFilterModelChange={onFilterChange}
                                components={{
                                    Pagination: CustomPagination,
                                    NoRowsOverlay: CustomNoRowsOverlay
                                }}
                                onSortModelChange={(sort) => handleSort(sort)}
                                // rowHeight={120}
                                getRowHeight={() => 'auto'}
                            />
                        </> :
                        // resume edit
                        <ResumeEdit resumeID={resumeEditID} />
                    }
                </div>

                {/* {/ popup for share page /} */}
                <ShareModule
                    open={openSingle}
                    close={onCloseModal}
                    filter={filterQuery}
                    apiServiceName={getResumeAnalysisReport}
                    dowunloadResponse={resumeAnalysisReportList}
                    moduleName="resumeAnalysis"
                    subject="Resume Analysis Report"
                />
            </div>
        </div>

    )
}
