import "./profile.scss";
import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
// import { useNavigate } from "react-router-dom";
import api from "../../redux/APIS/api";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from "@mui/material";
import Dropzone from 'react-dropzone';
import { imageUpload } from "../../redux/features/resourceSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { toast, ToastContainer } from "react-toastify";
const { REACT_APP_API_BASEURL} = process.env;

const Profile = () => {
  const navigate = useNavigate()
  const { Id } = useParams()
  const [user, setUser] = useState({});
  const {status,error, profilePhoto} = useSelector(state => state.resource);

  const dispatch = useDispatch();
  // const navigate=useNavigate()

  const loadProfile = async () => {
    if (Id > 0) {
      const res = await api.get(`/resources/main/${Id}`)
        .then().catch(error => console.log(error))
      if (res && res.data && res.data.data) {
        setUser(res.data.data);
      }
    } else {
      const res = await api.get('/user')
        .then().catch(error => console.log(error))
      if (res && res.data && res.data.data) {
        setUser(res.data.data);
      }
    }
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const [flag, setFlag] = useState(false);
  const [fileErr, setFileErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [invalidExtension,setInvalidExtension] =useState("");

  const onFileChange = (event) => { 
      setInvalidExtension("")
      let file = event[0];
      const fileType = file.name.split(".")[1];
      let image_extension =["jpg", "jpeg","png"]
      if (image_extension.includes(fileType.toLowerCase())) {
          setFileErr("");
          setSelectedImage(file);
          const getFormData=(obj)=> {
            const formData = new FormData();
            Object.keys(obj).forEach(key => formData.append(key, obj[key]));
            return formData;
        }
          let obj={
            id: user.id, 
            file: file,
            display_name: file.name.split(".")[0],
            upload_type: 'resource'
        }
      
        dispatch(imageUpload(getFormData(obj)));
        setSelectedImage(null);
        setLoader(true);
       
      } else {
        setFlag(false)
          setSelectedImage(null);
          setFileErr("You can only upload jpg, jpeg, png files")
      }
  }


  // const onEdit=() => { 
  //   // console.log(user);
  //   navigate("/edit-profile", {state:user})
  //  }

  // if(loader==true && profilePhoto.status===200){

        if (loader === true && status === STATUSES.SUCCESS && profilePhoto && profilePhoto.message) {
          if (profilePhoto.status === 200) {
            loadProfile();
            setLoader(false);
            toast.success(profilePhoto.message);
              }
        if (profilePhoto.status === 400) {
          setLoader(false);
          setInvalidExtension(profilePhoto.message);
          toast.error(profilePhoto.message);
          }
      }

      if (loader === true && status === STATUSES.FAILURE && error && error.message) {
        setLoader(false)
        toast.error(error.message);
    }

  useEffect(() => {
    loadProfile()
  },[])


  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">

            <div className="item">
            {loader === true && <LoaderForm />}
              {user.avatar_path  ?
                <img src={REACT_APP_API_BASEURL + user.avatar_path.split("").slice(1).join("")}
                  alt="" className="itemImg" />
                :
                <img src="https://images.unsplash.com/photo-1504376379689-8d54347b26c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=536&q=80"
                  alt="" className="itemImg" />
              }
                  <div className="file-dropzone-tabs">
                                          <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)} maxFiles="1">
                                              {({ getRootProps, getInputProps  }) => (
                                                  <section>
                                                      <div {...getRootProps()}>
                                                          <input {...getInputProps()} />
                                                          <p>{selectedImage && selectedImage !== null  ? selectedImage.name : "Drag 'n' drop photo here, or click to upload photo"}</p>
                                                      </div>
                                                  </section>
                                              )}
                                          </Dropzone>
                                      </div>
                                      {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                                      {invalidExtension !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{invalidExtension}</p>}
                                    
                  
            </div>
          </div>
          <div className="right">
            {/* <Link to={"/edit-profile"} className="editButton">Edit</Link> */}
            {/* <button onClick={onEdit} className="editButton">Edit</button> */}
            <Tooltip title="Back">
              <button className="editButton" onClick={() => navigate(-1)}><ArrowBackIcon /></button>
            </Tooltip>
            <h1 className="title">Information</h1>
            <div className="details">
              {user.display_name &&
                <div className="detailItem">
                  <span className="itemKey">Name :</span>
                  <span className="itemKey2">{user.display_name}</span>
                </div>
              }
              {user.email &&
                <div className="detailItem">
                  <span className="itemKey">Email :</span>
                  <span className="itemKey2">{user.email}</span>
                </div>
              }
              {user.contact_no &&
                <div className="detailItem">
                  <span className="itemKey">Contact no. :</span>
                  <span className="itemKey2">{user.contact_no}</span>
                </div>
              }
              {user.date_of_birth &&
                <div className="detailItem">
                  <span className="itemKey">Date of Birth :</span>
                  <span className="itemKey2"> {user.date_of_birth}</span>
                </div>
              }
              {user.tms_role && user.tms_role.role_name &&
                <div className="detailItem">
                  <span className="itemKey">Role :</span>
                  <span className="itemKey2">{user.tms_role.role_name}</span>
                </div>
              }
              {user.department && user.department.name &&
                <div className="detailItem">
                  <span className="itemKey">Department :</span>
                  <span className="itemKey2">{user.department.name}</span>
                </div>
              }
              {user.address &&
                <div className="detailItem">
                  <span className="itemKey">Address :</span>
                  <span className="itemKey2"> {user.address}</span>
                </div>
              }

              {user.city && user.city.city_name &&
                <div className="detailItem">
                  <span className="itemKey">City :</span>
                  <span className="itemKey2">{user.city.city_name}</span>
                </div>
              }
              {user.state && user.state.name &&
                <div className="detailItem">
                  <span className="itemKey">State :</span>
                  <span className="itemKey2">{user.state.name}</span>
                </div>
              }
              {user.country && user.country.name &&
                <div className="detailItem">
                  <span className="itemKey">Country :</span>
                  <span className="itemKey2">{user.country.name}</span>
                </div>
              }

              {user.jira_account &&
                <div className="detailItem">
                  <span className="itemKey">Jira account :</span>
                  <span className="itemKey2"> {user.jira_account}</span>
                </div>
              }

              {user.emp_code &&
                <div className="detailItem">
                  <span className="itemKey">Employee Code :</span>
                  <span className="itemKey2">{user.emp_code}</span>
                </div>
              }

              {user.reporting_manager_name &&
                <div className="detailItem">
                  <span className="itemKey">Manager :</span>
                  <span className="itemKey2">{user.reporting_manager_name}</span>
                </div>
              }

              {user.domains_data &&
                <div className="detailItem">
                  <span className="itemKey">Domain :</span>
                  <span className="itemKey2">{user.domains_data[0].domain.name}</span>
                </div>
              }


              {user.employment_type &&
                <div className="detailItem">
                  <span className="itemKey">Employment Type :</span>
                  <span className="itemKey2">{user.employment_type.employment_type}</span>
                </div>
              }

              {user.shift &&
                <div className="detailItem">
                  <span className="itemKey">Shift Name :</span>
                  <span className="itemKey2">{[user.shift.shift_name]}</span>
                </div>
              }
                {user.shift &&
                <div className="detailItem">
                  <span className="itemKey">Shift Time :</span>
                  <span className="itemKey2">{[user.shift.timing_display]}</span>
                </div>
              }

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Profile