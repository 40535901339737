import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

// get all menu list
export const getMenuList = createAsyncThunk(
    "get/menu-list",
    async (data) => {
        const res = await dataService.menuList(data);
        const resp = res.data;
        return resp;
    });
export const postBaseMenu = createAsyncThunk(
    "post/base-menu",
    async (data) => {
        const res = await dataService.postmenu(data);
        const resp = res.data;
        return resp;
    });

export const getRoleMenuList = createAsyncThunk(
    "get/role-menu-list",
    async (data) => {
        const res = await dataService.getRoleMenu(data);
        const resp = res.data;
        return resp;
    });
// base/menu-role
export const postSingleBaseMenu = createAsyncThunk(
    "post-single/base-menu",
    async (data) => {
        const res = await dataService.postSingleBaseMenu(data);
        const resp = res.data;
        return resp;
    });
export const postAssignMenuRole = createAsyncThunk(
    "post-multipe/base-menu",
    async (data) => {
        const res = await dataService.postAssignMenuRole(data);
        const resp = res.data;
        return resp;
    });
//get all menu for parent
export const getAllMenuList = createAsyncThunk("get/all-menu-list", async (data) => {
    const res = await dataService.menuList(data);
    const resp = res.data;
    return resp;
});

export const getAsigneRoleMenuList = createAsyncThunk("get/assigne-role-menu-list", async (data) => {
    if (data.tokens) {
        const res = await dataService.getRoleMenus(data);
        const resp = res.data;
        return resp;
    }
    const res = await dataService.getRoleMenu(data);
    const resp = res.data;
    return resp;
});

export const postUploadResume = createAsyncThunk("post/upload-resume", async (data) => {
    const res = await dataService.postResume(data);
    const resp = res.data;
    return resp;
});

//////skill apis///////
export const getAllSkillList = createAsyncThunk("skill/all-list", async (data) => {
    const res = await dataService.all_skill_list(data);
    return res.data;
});

export const getSkillList = createAsyncThunk("skill/list", async (data) => {
    const res = await dataService.skill_list(data);
    return res.data;
});

export const skillCreate = createAsyncThunk("skill/create", async (data) => {
    const res = await dataService.skill_create(data);
    return res.data;
});

export const skillEdit = createAsyncThunk("skill/edit", async (id) => {
    const res = await dataService.skill_edit(id);
    return res.data;
});

export const skillUpdate = createAsyncThunk("skill/update", async (data) => {
    const res = await dataService.skill_update(data.ID, data.addvalues);
    return res.data;
});

export const skillArchive = createAsyncThunk("skill/archive", async (id) => {
    const res = await dataService.skill_archive(id);
    return res.data;
});

//////Industry apis///////
export const getAllIndustryList = createAsyncThunk("industry/all-list", async (data) => {
    const res = await dataService.all_industry_list(data);
    return res.data;
});

export const getIndustryList = createAsyncThunk("industry/list", async (data) => {
    const res = await dataService.industry_list(data);
    return res.data;
});

export const industryCreate = createAsyncThunk("industry/create", async (data) => {
    const res = await dataService.industry_create(data);
    return res.data;
});

export const industryEdit = createAsyncThunk("industry/edit", async (id) => {
    const res = await dataService.industry_edit(id);
    return res.data;
});

export const industryUpdate = createAsyncThunk("industry/update", async (data) => {
    const res = await dataService.industry_update(data.ID, data.addvalues);
    return res.data;
});

export const industryArchive = createAsyncThunk("industry/archive", async (id) => {
    const res = await dataService.industry_archive(id);
    return res.data;
});

//////Qualification apis///////
export const getAllQualificationList = createAsyncThunk("qualification/all-list", async (data) => {
    const res = await dataService.all_qualification_list(data);
    return res.data;
});

export const getQualificationList = createAsyncThunk("qualification/list", async (data) => {
    const res = await dataService.qualification_list(data);
    return res.data;
});

export const qualificationCreate = createAsyncThunk("qualification/create", async (data) => {
    const res = await dataService.qualification_create(data);
    return res.data;
});

export const qualificationEdit = createAsyncThunk("qualification/edit", async (id) => {
    const res = await dataService.qualification_edit(id);
    return res.data;
});

export const qualificationUpdate = createAsyncThunk("qualification/update", async (data) => {
    const res = await dataService.qualification_update(data.ID, data.addvalues);
    return res.data;
});

export const qualificationArchive = createAsyncThunk("qualification/archive", async (id) => {
    const res = await dataService.qualification_archive(id);
    return res.data;
});

export const createAttachment = createAsyncThunk("post/attachment", async (data) => {
    const res = await dataService.create_attachment(data);
    const resp = res.data;
    return resp;
});


//////Report Master apis///////
export const getReportMasterList = createAsyncThunk("report-master/all-list", async (data) => {
    const res = await dataService.all_report_master(data);
    return res.data;
});

export const reportMasterCreate = createAsyncThunk("report-master/create", async (data) => {
    const res = await dataService.report_master_create(data);
    return res.data;
});

export const reportMasterEdit = createAsyncThunk("report-master/edit", async (id) => {
    const res = await dataService.report_master_edit(id);
    return res.data;
});

export const reportMasterUpdate = createAsyncThunk("report-master/update", async (data) => {
    const res = await dataService.report_master_update(data.ID, data.addvalues);
    return res.data;
});

export const getNotificationList = createAsyncThunk("notification/list", async (data) => {
    const res = await dataService.get_notification_list(data);
    return res.data;
});

export const getQuestionLevelList = createAsyncThunk("questionlevel/list", async (data) => {
    const res = await dataService.questionlevel_list(data);
    return res.data;
});

export const getTechnologyList = createAsyncThunk("technology/list", async (data) => {
    const res = await dataService.technology_list(data);
    return res.data;
});

export const getQuestionSetList = createAsyncThunk("questionset/all-list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.questionSet_list(data);
    return res.data;
});

export const candidateCreate = createAsyncThunk("candidate/create", async (data) => {
    if(data === 'type'){
        return []
    }
    const res = await dataService.create_candidate(data);
    return res.data;
});

export const createCandidateExamSchedule = createAsyncThunk("create/candidate-exam-schedule", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.candidate_exam_schedule_create(data);
    const resp = res.data;
    return resp;
});

const masterSlice = createSlice({
    name: "master",
    initialState: {
        status: 'idle',
        error: '',

        menuList: [],
        createMenu: [],
        createRoleMenu: [],
        roleMenuList: [],
        createAssignRoleMenu: [],
        allMenuList: [],
        assigneMenu: [],

        createUploadResume: [],

        skill_AllList: [],
        skill_Create: [],
        skill_List: [],
        skill_Edit: [],
        skill_update: [],
        skill_Archive: [],

        industry_AllList: [],
        industry_Create: [],
        industry_List: [],
        industry_Edit: [],
        industry_update: [],
        industry_Archive: [],

        qualification_AllList: [],
        qualification_Create: [],
        qualification_List: [],
        qualification_Edit: [],
        qualification_update: [],
        qualification_Archive: [],

        create_Attachment: [],

        reportMaster_List: [],
        reportMaster_Create: [],
        reportMaster_Edit: [],
        reportMaster_update: [],

        notificationList: [],

        questionset_List: [],
        questionlevel_List: [],
        technology_List: [],
        createCandidate: [],
        candidateExamScheduleCreate: [],
        candidateStatus: 'idle',
        candidateError: "",
        candidateCreateError:""

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get menu list 
            .addCase(getMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.menuList = action.payload;

            })
            .addCase(getMenuList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            //post menu  
            .addCase(postBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createMenu`;
                state.createMenu = action.payload;

            })
            .addCase(postBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createMenu`;
                state.error = action.error
            })
            //post and update single menu role
            .addCase(postSingleBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postSingleBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createRoleMenu`;
                state.createRoleMenu = action.payload;

            })
            .addCase(postSingleBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createRoleMenu`;
                state.error = action.error
            })
            //get menu role list
            .addCase(getRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.roleMenuList = action.payload;

            })
            .addCase(getRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //post Assign menu role 
            .addCase(postAssignMenuRole.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postAssignMenuRole.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createAssignRoleMenu`;
                state.createAssignRoleMenu = action.payload;
            })
            .addCase(postAssignMenuRole.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createAssignRoleMenu`;
                state.error = action.error
            })

            // get parent menu
            .addCase(getAllMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.allMenuList = action.payload;

            })

            //get assigne menu list by role
            .addCase(getAsigneRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAsigneRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.dataLoader = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        created_by: e.created_by,
                        created_on: e.created_on,
                        menu_id: e.menu_id,
                        menu: e.menu && e.menu.url_slug,
                        menu_title: e.menu && e.menu.title,
                        menu_status: e.menu && e.menu.status,
                        parent_menu_id: e.parent_menu_id,
                        parent_menu: e.parent_menu && e.parent_menu.url_slug,
                        parent_menu_status: e.parent_menu && e.parent_menu.status,
                        role_id: e.role_id,
                        updated_by: e.updated_by,
                        updated_on: e.updated_on,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray.filter(e => e.menu_status === true && (e.parent_menu === null || e.parent_menu_status === true)), "count": count } }
                }
                state.assigneMenu = newArray2 && newArray2.data ? newArray2 : action.payload;
                localStorage.setItem('menuData', JSON.stringify(newArray2))
            })
            .addCase(getAsigneRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //post Upload resume
            .addCase(postUploadResume.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postUploadResume.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createUploadResume`;
                state.createUploadResume = action.payload;

            })
            .addCase(postUploadResume.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createUploadResume`;
                state.error = action.error
            })

            //////////skill store section/////////
            ///all skill  list
            .addCase(getAllSkillList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllSkillList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.skill_AllList = action.payload;
            })
            .addCase(getAllSkillList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd skill list
            .addCase(getSkillList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSkillList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.skill_List = action.payload;
            })
            .addCase(getSkillList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///skill create
            .addCase(skillCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_skill_Create`;
                state.skill_Create = action.payload;
            })
            .addCase(skillCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///skill edit
            .addCase(skillEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.skill_Edit = action.payload;
            })
            .addCase(skillEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///skill Update
            .addCase(skillUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_skill_Update`;
                state.skill_Update = action.payload;
            })
            .addCase(skillUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///skill archive
            .addCase(skillArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(skillArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_skill_Archive`;
                state.skill_Archive = action.payload;
            })
            .addCase(skillArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////Industry store section/////////
            ///all industry  list
            .addCase(getAllIndustryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllIndustryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_AllList = action.payload;
            })
            .addCase(getAllIndustryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd industry list
            .addCase(getIndustryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getIndustryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_List = action.payload;
            })
            .addCase(getIndustryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///industry create
            .addCase(industryCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Create`;
                state.industry_Create = action.payload;
            })
            .addCase(industryCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry edit
            .addCase(industryEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.industry_Edit = action.payload;
            })
            .addCase(industryEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry Update
            .addCase(industryUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Update`;
                state.industry_Update = action.payload;
            })
            .addCase(industryUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///industry archive
            .addCase(industryArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(industryArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_industry_Archive`;
                state.industry_Archive = action.payload;
            })
            .addCase(industryArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //////////Qualification store section/////////
            ///all qualification  list
            .addCase(getAllQualificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllQualificationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_AllList = action.payload;
            })
            .addCase(getAllQualificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd qualification list
            .addCase(getQualificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQualificationList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_List = action.payload;
            })
            .addCase(getQualificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///qualification create
            .addCase(qualificationCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Create`;
                state.qualification_Create = action.payload;
            })
            .addCase(qualificationCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification edit
            .addCase(qualificationEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.qualification_Edit = action.payload;
            })
            .addCase(qualificationEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification Update
            .addCase(qualificationUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Update`;
                state.qualification_Update = action.payload;
            })
            .addCase(qualificationUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///qualification archive
            .addCase(qualificationArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(qualificationArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_qualification_Archive`;
                state.qualification_Archive = action.payload;
            })
            .addCase(qualificationArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// create attachment 
            .addCase(createAttachment.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createAttachment.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_create_Attachment`;
                state.create_Attachment = action.payload;
            })
            .addCase(createAttachment.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///report master store section///
            //all report master list
            .addCase(getReportMasterList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getReportMasterList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.reportMaster_List = action.payload;
            })
            .addCase(getReportMasterList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //report master create
            .addCase(reportMasterCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(reportMasterCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_reportMaster_Create`;
                state.reportMaster_Create = action.payload;
            })
            .addCase(reportMasterCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //report master edit
            .addCase(reportMasterEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(reportMasterEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.reportMaster_Edit = action.payload;
            })
            .addCase(reportMasterEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //report master Update
            .addCase(reportMasterUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(reportMasterUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_reportMaster_update`;
                state.reportMaster_update = action.payload;
            })
            .addCase(reportMasterUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //get notification list
            .addCase(getNotificationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getNotificationList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_notificationList`;
                state.notificationList = action.payload;
            })
            .addCase(getNotificationList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // ces section api call 
            //all questionSet list
            .addCase(getQuestionSetList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionSetList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res && res.length > 0 && res.map(e => {
                    return newArray.push({
                        id: e.id,
                        set_name: e.set_name,
                        maximum_time: e.maximum_time,
                        no_of_question: e.no_of_question,
                        set_key: e.set_key,
                        technology_id: e.technology_id,
                        technology: e.technology && e.technology.name,
                        level_id: e.level_id,
                        level: e.level && e.level.level_name,
                        created_by: e.created_by,
                        created_on: e.created_on,
                        updated_by: e.updated_by,
                        updated_on: e.updated_on,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.questionset_List = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getQuestionSetList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd questionlevel list
            .addCase(getQuestionLevelList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionLevelList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_List = action.payload;
            })
            .addCase(getQuestionLevelList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd technology list
            .addCase(getTechnologyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTechnologyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_List = action.payload;
            })
            .addCase(getTechnologyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///create candidate

            .addCase(candidateCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.candidateCreateError = ""
            })
            .addCase(candidateCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createCandidate`;
                state.createCandidate = action.payload;
                state.candidateCreateError = ""
            })
            .addCase(candidateCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.candidateCreateError = action.error
                state.createCandidate = []
                state.error = action.error
            })

            //get candidateExamScheduleCreate
            .addCase(createCandidateExamSchedule.pending, (state, action) => {
                state.candidateStatus = STATUSES.LOADING;
            })
            .addCase(createCandidateExamSchedule.fulfilled, (state, action) => {
                state.candidateStatus = `${STATUSES.SUCCESS}_candidateExamScheduleCreate`;
                state.candidateExamScheduleCreate = action.payload;
                state.candidateError = ""

            })
            .addCase(createCandidateExamSchedule.rejected, (state, action) => {
                state.candidateStatus = STATUSES.FAILURE;
                state.candidateError = action.error
                state.candidateExamScheduleCreate = []

            })

    },
});


export default masterSlice.reducer;