import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import FolderIcon from '@mui/icons-material/Folder';
import ContactsIcon from '@mui/icons-material/Contacts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/features/authSlice'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import BadgeIcon from '@mui/icons-material/Badge';
import LocalStorageDataService from '../common-function/GetLocalstorage';
import { Ballot, Assignment, MenuBook, TrendingUp } from "@mui/icons-material";
import Logo from '../../images/fevicon.png'
import { getMenuListForSideBar } from "../../redux/features/sidebarSlice";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

let user = LocalStorageDataService.userDetail();
let tmsRole = user && user.tms_role
const tmsRoleId = user && user.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRoleId},"operation": "eq"}},"no_limit":True}`

const Sidebar = () => {
  const { logoutUser, authLoggedIn } = useSelector((state) => state.authUser)
  const [ urlPath, setUrlPath ] = useState();
  const [ parentUrlPath, setParentUrlPath ] = useState();
  const { sidebarMenuList } = useSelector((state) => state.sidebar)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const logout_user = useCallback(() => {
    dispatch(logout());
    window.location.reload('/');
  }, []);

  if (authLoggedIn === false && logoutUser && logoutUser.message) {
    navigate('/')
  }

  const { data } = useSelector((state) => state.navbartoggle);

  const fetchUrlPath = () => {
    const menuData = JSON.parse(localStorage.getItem('menuData'));
    const path = window.location.href;
    let pathArr = path.split('/');
    pathArr = pathArr.splice(3);
    const propertyName = pathArr.join('/');
    const currentMenu = document.getElementById(propertyName);
    setUrlPath(propertyName);
    if(menuData!==null){
      const propertyObject = menuData.data.rows.filter((item) => item.menu === propertyName)[0];
      if (propertyObject) {
        if (propertyObject.parent_menu) {
          currentMenu?.parentNode.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
          const parentPropertyObject = menuData.data.rows.filter((item) => item.menu === propertyObject.parent_menu)[0];

          if (parentPropertyObject?.parent_menu)
            return setParentUrlPath(parentPropertyObject?.parent_menu)

          return setParentUrlPath(parentPropertyObject?.menu)
        }
        currentMenu?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
        return setParentUrlPath(propertyObject?.menu);
      }
    }
  };

  useEffect(() => fetchUrlPath(), [sidebarMenuList, dispatch])

  useEffect(() => {
    dispatch(getMenuListForSideBar(roleWiseFilter))
  }, [dispatch])


//implement parent menu toggle functionality
const openCloseMenu = (pathLink) => {    
  (parentUrlPath===pathLink) ? setParentUrlPath('') : setParentUrlPath(pathLink);       
}

  return (

    // dynamic side bar menu code using api 
    <div className={data === true ? 'sidebar-collapse sidebar' : 'sidebar'} >
      <div className="top">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="logo"><img src={Logo} alt={Logo} /><span className="logo-text">RTMS</span></span>
        </Link>
      </div>
      <ProSidebar>
        <Menu>
          {sidebarMenuList && sidebarMenuList.length > 0 && sidebarMenuList.map((item, index) => {
            return (
              item.submenu.length === 0 ?
                <MenuItem id={item.link} key={index} className={item.link === parentUrlPath ? "active" : ""} icon={
                  item.icon === "DashboardIcon" ? <DashboardIcon /> :
                    item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        item.icon === "FolderIcon" ? <FolderIcon /> :
                          item.icon === "ContactsIcon" ? <ContactsIcon /> :
                            item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                    item.icon === "Assignment" ? <Assignment /> :
                                      item.icon === "MenuBook" ? <MenuBook /> :
                                        item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                          item.icon === "Ballot" ? <Ballot /> :
                                            item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                              item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                item.icon === "TrendingUp" ? <TrendingUp /> :
                                                  <PictureAsPdfIcon />}>

                  {item.title}
                  <Link to={"/" + item.link} />
                </MenuItem>
                : item.submenu.length > 0 &&
                <SubMenu key={index}  title={item.title}
                onClick={() => { openCloseMenu(item.link) }}
                open = {item.link === parentUrlPath}
                icon={
                  item.icon === "DashboardIcon" ? <DashboardIcon /> :
                    item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        item.icon === "FolderIcon" ? <FolderIcon /> :
                          item.icon === "ContactsIcon" ? <ContactsIcon /> :
                            item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                    item.icon === "Assignment" ? <Assignment /> :
                                      item.icon === "MenuBook" ? <MenuBook /> :
                                        item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                          item.icon === "Ballot" ? <Ballot /> :
                                            item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                              item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                item.icon === "TrendingUp" ? <TrendingUp /> :
                                                  <PictureAsPdfIcon />}>

                  {item.submenu.map((subItem, subIndex) => {
                    if (!subItem.link.match("edit") && !subItem.link.match("view")) {
                      return (
                        subItem.menuItem.length === 0 ?

                          <MenuItem id={subItem.link} key={subIndex} active={urlPath === subItem.link}>
                            {subItem.title}
                            <Link to={"/" + subItem.link} />
                          </MenuItem>

                          : subItem.menuItem.length > 0 &&

                          <SubMenu key={subIndex} title={subItem.title} open={item.link === parentUrlPath}>

                            {subItem.menuItem.map((submenusItem, submenusIndex) => {
                              if (!submenusItem.link.match("edit") && !submenusItem.link.match("view")) {
                                return (
                                  <MenuItem id={submenusItem.link} key={submenusIndex} active={urlPath === submenusItem.link}>
                                    {submenusItem.title}
                                    {submenusItem.link && <Link to={"/" + submenusItem.link}></Link>}
                                  </MenuItem>
                                )
                              }
                              return true;
                            })
                            }

                          </SubMenu>

                        // <MenuItem key={subIndex}>
                        //   {subItem.title}
                        //   <Link to={"/" + subItem.link} />
                        // </MenuItem>
                      )
                    }
                    return true;
                  })}
                </SubMenu>
            )
          }
          )}
          <MenuItem icon={<LogoutIcon />} onClick={logout_user}>
            Logout
            <Link to={"/"} />
          </MenuItem>
        </Menu>
      </ProSidebar>
    </div>
  )
}

export default Sidebar
