
import Login from "./pages/login/Login";
import {
  editInput,
  departmentInputs,
  roleInputs,
  userTypeInputs,
  credentialHeadInputs
}
  from "./formSource";

import DashboardMenu from "./pages/dashboard/DashboardMenu";

import "./style/dark.scss";
import "./App.css"

import { PrivateRoute } from "./redux/helpers/PrivateRoute";

import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/edit-profile/EditProfile";
import Setting from "./pages/setting/Setting";
import Notification from "./pages/notification/Notification";
import Messages from "./pages/message/Message";
import AllManageUser from "./pages/manage-user/AllUser";
import AddManageUser from "./pages/manage-user/AddUser";
import AllDepartments from "./pages/manage-department/AllDepartments";
import AddDepartment from "./pages/manage-department/AddDepartment";
import AllRoles from "./pages/manage-roles/AllRoles";
import AddRole from "./pages/manage-roles/AddRole";
import ResourcesList from "./pages/resources/ResoucesList";
import Loader from "./pages/loader-pickers/Loader";
import DateTime from "./pages/loader-pickers/DateTimePickers";
import ResourceView from "./pages/resources/ResourceView";
import ResourceEdit from "./pages/resources/ResourceEdit";
import AssignShift from "./pages/manage-shift/AssignShift";
import ViewShift from "./pages/manage-shift/ViewShift";
import AllCredentialHeads from "./pages/resource-credentials/manage-credential-heads/AllCreadentialHeads";
import AddCredentialHead from "./pages/resource-credentials/manage-credential-heads/AddCredentialHead";
import AddResourceCredential from "./pages/resource-credentials/AddResourceCredential";
import AllResourceCredential from "./pages/resource-credentials/AllResourceCredential";
import Error404 from "./pages/error-pages/Error404";
import AllShifts from "./pages/manage-shift/AllShifts";
import AddShift from "./pages/manage-shift/AddShift";
import AttendanceCalender from "./pages/calender/AttendaceCalender";
import AttendancePresence from "./pages/calender/AttendancePresence";
import { TaskList, Task, TaskView, ReportedTask } from "./pages/task/index";
import JiraStatus from "./pages/manage-user-status/JiraStatus";
import AllProjects from "./pages/manage-project/AllProjects";
import AddProject from "./pages/manage-project/AddProject";
import ViewProject from "./pages/manage-project/ViewProject";
import SprintList from "./pages/sprint/SprintList";
import AttendanceReport from "./pages/reports/AttendanceReport";
import WorkLogReport from "./pages/reports/WorkLogReport";
import ViewAttendaceDetails from "./pages/reports/ViewAttendaceDetails";
import MyProjects from "./pages/manage-project/MyProjects";
import MyResourceCredential from "./pages/resource-credentials/MyResourceCredential";
import { Edittask } from "./pages/task/Edittask";
import { TaskEdit } from "./pages/task/TaskEdit";
import { MyTaskListNew } from "./pages/task/MyTaskListNew";
import ViewResourceCredential from "./pages/resource-credentials/ViewResourceCredential";
import TeamAttendanceList from "./pages/calender/TeamAttendanceList";
import CompOff from "./pages/calender/CompOff";
import TeamCompOff from "./pages/calender/TeamCompOff";
import ReportOfAttendance from './pages/reports/ReportsOfAttendance'
import TeamRegularization from "./pages/calender/TeamRegularization";
import MonthWiseAttendanceReport from "./pages/reports/MonthWiseAttendanceReport";
import MenuList from "./pages/master/MenuList";
import AttendanceReportStatus from "./pages/reports/AttendanceReportStatus";
import Holidays from "./pages/holiday/Holidays";
import HolidayCalender from "./pages/holiday/HolidayCalender";
import AdvanceListing from "./pages/advance-listing/AdvanceListing";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import LocalStorageDataService from "./components/common-function/GetLocalstorage";
import { getAsigneRoleMenuList } from "./redux/features/masterSlice";
import Error401 from "./pages/error-pages/Error401";
import ThirdParty from "./pages/master/ThirdParty";
import HolidayList from "./pages/holiday/HolidayList";
import Domain from "./pages/master/Domain";
import UploadResume from "./pages/master/UploadResume";
import ProjectDomain from "./pages/domain/ProjectDomain";
import { EpicTask } from "./pages/task/Epic";
import { StoryTask } from "./pages/task/Story";
import ThirdPartyDomainMapping from "./pages/master/ThirdPartyDomainMapping";
import Workflows from "./pages/workflow/Workflows";
import WorkflowList from "./pages/workflow/WorkflowList";
import JobPost from "./pages/master/JobPost";
import Skill from "./pages/master/Skill";
import Industry from "./pages/master/Industry";
import Qualification from "./pages/master/Qualification";

import DepartmentDomain from "./pages/manage-department/DepartmentDomain";

import ThirdPartyCredentialHead from "./pages/master/ThirdPartyCredentialHead";
import { PhoneCalling } from "./pages/phone-calling/PhoneCalling";
import EditWorkflowMapping from "./pages/workflow/EditWorkflowMapping";
import MyJob from "./pages/task/MyJob";
import { PhoneCallDetails } from "./pages/phoneCallDetails/PhoneCallDetails";
import { MyCallDetails } from "./pages/phoneCallDetails/MyCallDetails";
import { ZoomCallDetails } from "./pages/zoomcallDetails/ZoomCallDetails";
import SovernCredentials from "./pages/master/sovern-credentials/SovernCredentials";
import AddSovernCredential from "./pages/master/sovern-credentials/AddSovernCredential";
import AgentCallPerformance from "./pages/reports/AgentCallPerformance";
import ResumeUploadReports from "./pages/reports/ResumeUploadReports";
import ResumeAnalysisReport from "./pages/reports/ResumeAnalysisReport";
import ReportMaster from "./pages/master/report-master/ReportMaster";
import DefaulsSetting from "./pages/manage-project/DefaulsSetting";
import SourceType from "./pages/master/marketing-masters/SourceType";
import ClientList from "./pages/marketing/ClientList";
import ClientAdd from "./pages/marketing/ClientAdd";
import CommunicationType from "./pages/master/marketing-masters/CommunicationType";
import DefaultScreeningFormList from "./pages/master/candidate-screening-form/DefaultScreeningFormList";
import ResumeList from "./pages/resume/ResumeList";
import ResumeEdit from "./pages/resume/ResumeEdit";

const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role_id
const roleWiseFilter = tmsRole ? `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRole},"operation": "eq"}},"no_limit":True}` : ""
function App() {

  // Acl Router
  const dispatch = useDispatch()
  useEffect(() => {
    roleWiseFilter && dispatch(getAsigneRoleMenuList(roleWiseFilter))
    // eslint-disable-next-line
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
        <Route
          exact path="/dashboard"
          element={
            <PrivateRoute path="dashboard?reload=done">
              <DashboardMenu />
            </PrivateRoute>
          }
        />


        <Route path="/profile" element={<PrivateRoute path="profile"><Profile /></PrivateRoute>} />
        <Route path="/profile/:Id" element={<PrivateRoute path="profile"><Profile /></PrivateRoute>} />
        <Route path="/edit-profile" element={<PrivateRoute path="edit-profile"><EditProfile inputs={editInput} title="Edit Profile" /></PrivateRoute>} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/all-massages" element={<Messages />} />

        {/* User Type */}

        <Route path="/all-user" element={
          <PrivateRoute path="all-user"><AllManageUser /></PrivateRoute>
        } />

        <Route path="/add-new-user" element={
          <PrivateRoute path="add-new-user"><AddManageUser inputs={userTypeInputs} title="Add New Employment Type" /></PrivateRoute>
        } />
        <Route path="/edit-user/:id" element={
          <PrivateRoute path="edit-user">
            <AddManageUser inputs={userTypeInputs} title="Edit Employment Type" />
          </PrivateRoute>
        } />

        {/* departments */}

        <Route path="/all-depratment" element={<PrivateRoute path="all-depratment"><AllDepartments /></PrivateRoute>} />
        <Route path="/add-new-department" element={
          <PrivateRoute path="add-new-department">
            <AddDepartment inputs={departmentInputs} title="Add New Department" />
          </PrivateRoute>
        } />
        <Route path="/edit-department/:id" element={
          <PrivateRoute path="edit-department">
            <AddDepartment title="Edit Department" inputs={departmentInputs} />
          </PrivateRoute>
        } />
        <Route path="/depratment-domains" element={<PrivateRoute path="depratment-domains"><DepartmentDomain /></PrivateRoute>} />

        {/* roles */}
        <Route path="/roles" element={<PrivateRoute path="roles"><AllRoles /></PrivateRoute>} />
        <Route path="/add-new-role" element={
          <PrivateRoute path="add-new-role">
            <AddRole inputs={roleInputs} title="Add New Role" />
          </PrivateRoute>
        } />
        <Route path="/edit-role/:id" element={
          <PrivateRoute path="edit-role">
            <AddRole inputs={roleInputs} title="Edit Role" />
          </PrivateRoute>
        } />

        {/* Resource  Route */}
        <Route path="/all-resource">
          <Route index element={<PrivateRoute path="all-resource"><ResourcesList /></PrivateRoute>} />
          <Route path="edit/:resourceid" element={<PrivateRoute path="all-resource/edit"><ResourceEdit /></PrivateRoute>} />
          <Route path="add" element={<PrivateRoute path="all-resource/add"><ResourceEdit /></PrivateRoute>} />
          <Route path="view/:resourceid" element={<PrivateRoute path="all-resource/view"><ResourceView /></PrivateRoute>} />
        </Route>

        <Route path="/all-shift" element={<PrivateRoute path="all-shift"><AllShifts /></PrivateRoute>} />
        <Route path="/add-new-shift" element={<PrivateRoute path="add-new-shift"><AddShift /></PrivateRoute>} />
        <Route path="/assign-shift" element={<PrivateRoute path="assign-shift"><AssignShift /></PrivateRoute>} />
        <Route path="/view-shift/:id" element={<PrivateRoute path="view-shift"><ViewShift /></PrivateRoute>} />

        {/* Resource Credential Heades */}
        <Route path="/all-credentials-head" element={<PrivateRoute path="all-credentials-head"><AllCredentialHeads /></PrivateRoute>} />
        <Route path="/add-credentials-head" element={
          <PrivateRoute path="add-credentials-head">
            <AddCredentialHead inputs={credentialHeadInputs} title="Add New Credential Head" />
          </PrivateRoute>
        } />
        <Route path="/edit-credentials-head/:id" element={
          <PrivateRoute path="edit-credentials-head">
            <AddCredentialHead inputs={credentialHeadInputs} title="Edit Credential Head" />
          </PrivateRoute>
        } />

        {/* Resource Credentials */}
        <Route path="/all-resource-credential" element={<PrivateRoute path="all-resource-credential" ><AllResourceCredential /></PrivateRoute>} />
        <Route path="/add-resource-credential" element={
          <PrivateRoute path="add-resource-credential">
            <AddResourceCredential inputs={credentialHeadInputs} title="Add New Resource Credential" />
          </PrivateRoute>
        } />
        <Route path="/edit-resource-credential/:id" element={
          <PrivateRoute path="edit-resource-credential">
            <AddResourceCredential inputs={credentialHeadInputs} title="Edit Resource Credential" />
          </PrivateRoute>
        } />

        <Route path="*" element={<Error404 />} />

        <Route path="/loader" element={<PrivateRoute path="loader"><Loader /></PrivateRoute>} />
        <Route path="/date-time-pickers" element={<PrivateRoute path="date-time-pickers"><DateTime /></PrivateRoute>} />
        <Route path="calender" element={<PrivateRoute path="calender"><AttendanceCalender /></PrivateRoute>} />
        {/* <Route path="/:resourceid" element={<PrivateRoute><AttendanceCalender /></PrivateRoute>} /> */}
        <Route path="my-presence" element={<PrivateRoute path="my-presence"><AttendancePresence /></PrivateRoute>} />
        <Route path="team-attendance" element={<PrivateRoute path="team-attendance"><TeamAttendanceList /></PrivateRoute>} />
        <Route path="team-regularization" element={<PrivateRoute path="team-regularization"><TeamRegularization /></PrivateRoute>} />
        <Route path="my-comp-off" element={<PrivateRoute path="my-comp-off"><CompOff /></PrivateRoute>} />
        <Route path="team-comp-off" element={<PrivateRoute path="team-comp-off"><TeamCompOff /></PrivateRoute>} />

        {/* Task Route */}

        <Route path="task" element={<PrivateRoute path="task"><TaskList /></PrivateRoute>} />
        <Route path="task/add" element={<PrivateRoute path="task/add"><Task /></PrivateRoute>} />
        <Route path="task/edit/:taskId" element={<PrivateRoute path="task/edit"><TaskEdit /></PrivateRoute>} />
        <Route path="task/view/:taskId" element={<PrivateRoute path="task/view"><TaskView /></PrivateRoute>} />

        <Route path="my-task" element={<PrivateRoute path="my-task"><MyTaskListNew /></PrivateRoute>} />
        <Route path="reported-task" element={<PrivateRoute path="reported-task"><ReportedTask /></PrivateRoute>} />


        <Route path="all-jira">
          <Route index element={<PrivateRoute path="all-jira"><JiraStatus /></PrivateRoute>} />
          <Route path="add" element={<PrivateRoute path="all-jira/add"><JiraStatus /></PrivateRoute>} />
        </Route>
        <Route path="third-party" element={<PrivateRoute path="third-party"><ThirdParty /></PrivateRoute>} />


        <Route path="/all-projects" element={<PrivateRoute path="all-projects"><AllProjects /></PrivateRoute>} />
        <Route path="/add-new-project" element={<PrivateRoute path="add-new-project"><AddProject /></PrivateRoute>} />
        <Route path="/edit-project" element={<PrivateRoute path="edit-project"><AddProject /></PrivateRoute>} />
        <Route path="/my-projects" element={<PrivateRoute path="my-projects"><MyProjects /></PrivateRoute>} />
        <Route path="/view-project" element={<PrivateRoute path="view-project"><ViewProject /></PrivateRoute>} />
        <Route path="/sprint-list" element={<PrivateRoute path="sprint-list"><SprintList /></PrivateRoute>} />

        <Route path="report-attendance" >
          <Route index element={<PrivateRoute path="report-attendance"><AttendanceReport /></PrivateRoute>} />
          <Route path="report" element={<PrivateRoute path="report-attendance/report"><ViewAttendaceDetails /></PrivateRoute>} />

        </Route>
        <Route path="/worklog-report" element={<PrivateRoute path="worklog-report"><WorkLogReport /></PrivateRoute>} />
        <Route path="/upload-report" element={<PrivateRoute path="upload-report"><ResumeUploadReports /></PrivateRoute>} />

        <Route path="/my-resource-credential" element={<PrivateRoute path="my-resource-credential"><MyResourceCredential /></PrivateRoute>} />
        <Route path="/view-credential/:id" element={<PrivateRoute path="view-credential"><ViewResourceCredential /></PrivateRoute>} />

        <Route path="/edit-task" element={<PrivateRoute path="edit-task"><Edittask /></PrivateRoute>} />
        <Route path="/attendance-report" element={<PrivateRoute path="attendance-report"><ReportOfAttendance /></PrivateRoute>} />
        <Route path="/month-wise-report" element={<PrivateRoute path="month-wise-report"><MonthWiseAttendanceReport /></PrivateRoute>} />
        <Route path="/master-menu-list" element={<PrivateRoute path="master-menu-list"><MenuList /></PrivateRoute>} />
        <Route path="/report-status" element={<PrivateRoute path="report-status"><AttendanceReportStatus /></PrivateRoute>} />
        <Route path="/advance-listing" element={<PrivateRoute path="advance-listing"><AdvanceListing /></PrivateRoute>} />

        <Route path="/access-denied" element={<Error401 />} />

        <Route path="/holidays" element={<PrivateRoute path="holidays"><Holidays /></PrivateRoute>} />
        <Route path="/holidays-list" element={<PrivateRoute path="holidays-list"><HolidayList /></PrivateRoute>} />
        <Route path="/holiday-calender" element={<PrivateRoute path="holiday-calender"><HolidayCalender /></PrivateRoute>} />

        <Route path="/domains" element={<PrivateRoute path="domains"><Domain /></PrivateRoute>} />
        <Route path="/project-domains" element={<PrivateRoute path="project-domains"><ProjectDomain /></PrivateRoute>} />

        <Route path="/upload-resume" element={<PrivateRoute path="upload-resume"><UploadResume /></PrivateRoute>} />
        <Route path="/epic-task" element={<PrivateRoute path="task"><EpicTask /></PrivateRoute>} />
        <Route path="/epic-task/:epicId" element={<PrivateRoute path="my-task"><EpicTask /></PrivateRoute>} />

        <Route path="/story-task" element={<PrivateRoute path="task"><StoryTask /></PrivateRoute>} />
        <Route path="/story-task/:epicId" element={<PrivateRoute path="my-task"><StoryTask /></PrivateRoute>} />
        <Route path="/third-party-domains" element={<PrivateRoute path="third-party-domains"><ThirdPartyDomainMapping /></PrivateRoute>} />

        <Route path="/workflows" element={<PrivateRoute path="workflows"><WorkflowList /></PrivateRoute>} />
        <Route path="/project-workflows-mapping" element={<PrivateRoute path="workflows"><Workflows /></PrivateRoute>} />
        <Route path="/edit-project-workflows-mapping" element={<PrivateRoute path="workflows"><EditWorkflowMapping /></PrivateRoute>} />
        <Route path="/job-post" element={<PrivateRoute path="job-post"><JobPost /></PrivateRoute>} />
        <Route path="/job-post/:jobId" element={<PrivateRoute path="job-post"><JobPost /></PrivateRoute>} />
        <Route path="/skills" element={<PrivateRoute path="skills"><Skill /></PrivateRoute>} />
        <Route path="/industry" element={<PrivateRoute path="industry"><Industry /></PrivateRoute>} />
        <Route path="/qualification" element={<PrivateRoute path="qualification"><Qualification /></PrivateRoute>} />

        <Route path="/third-party-credential" element={<PrivateRoute path="third-party-credential"><ThirdPartyCredentialHead /></PrivateRoute>} />
        <Route path="/phone-calling" element={<PrivateRoute path="third-party-credential"><PhoneCalling /></PrivateRoute>} />

        <Route path="/my-jobs" element={<PrivateRoute path="my-jobs"><MyJob /></PrivateRoute>} />

        <Route path="/all-call-details" element={<PrivateRoute path="all-call-details"><PhoneCallDetails /></PrivateRoute>} />
        <Route path="/my-call-details" element={<PrivateRoute path="my-call-details"><MyCallDetails /></PrivateRoute>} />
        <Route path="/zoom-call-details" element={<PrivateRoute path="zoom-call-details"><ZoomCallDetails /></PrivateRoute>} />

        <Route path="/sovern-credentials" element={<PrivateRoute path="sovern-credentials"><SovernCredentials /></PrivateRoute>} />
        <Route path="/add-sovern-credential" element={<PrivateRoute path="add-sovern-credential"><AddSovernCredential /></PrivateRoute>} />

        <Route path="/agent-call-performance-report" element={<PrivateRoute path="agent-call-performance-report"><AgentCallPerformance /></PrivateRoute>} />
        <Route path="/resume-analysis-report" element={<PrivateRoute path="resume-analysis-report"><ResumeAnalysisReport /></PrivateRoute>} />

        <Route path="/report-master" element={<PrivateRoute path="report-master"><ReportMaster /></PrivateRoute>} />
        <Route path="/domain-project-default-setting" element={<PrivateRoute path="domain-project-default-setting"><DefaulsSetting /></PrivateRoute>} />
        <Route path="/default-screening-form" element={<PrivateRoute path="default-screening-form"><DefaultScreeningFormList /></PrivateRoute>} />


        {/* marketing routes */}
        <Route path="/source-type" element={<PrivateRoute path="source-type"><SourceType /></PrivateRoute>} />
        <Route path="/marketing-clients" element={<PrivateRoute path="marketing-clients"><ClientList /></PrivateRoute>} />
        <Route path="/marketing-clients-add" element={<PrivateRoute path="marketing-clients-add"><ClientAdd /></PrivateRoute>} />


        <Route path="/resume-list" element={<PrivateRoute path="resume-list"><ResumeList /></PrivateRoute>} />
        <Route path="/resume-list/:resumeId" element={<PrivateRoute path="resume-list"><ResumeEdit /></PrivateRoute>} />

        <Route path="/communication-type" element={<PrivateRoute path="communication-type"><CommunicationType /></PrivateRoute>} />

      </Routes>

    </BrowserRouter>
  );
}

export default App;
