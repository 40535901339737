export const DefaultFilterColumns = {
    //list columns
    MYTASK_LIST_COLUMNS: ['"id"', '"summary"', ' "status_id"', '"job_id"', '"created_on"', '"sprint"', '"epic_id"', '"assignee_id"', '"reported_by"', '"issue_type"', '"project_id"', '"ticket_key"', '"status"', '"story_point"', '"updated_on"', '"is_jira_issue"', '"priority"', '"status__name"', '"jira_issue_id"'],
    TASK_DETAILS_COLUMNS: ['"id"', '"assignee_id"', '"attachments"', '"created_by"', '"description"', '"labels"', '"priority"', '"project_id"', '"sprint"', '"status_id"', '"status__name"', '"story_point"', '"summary"', '"ticket_key"'],
    ZOOMCAAL_DEATILS_COLUMNS: ['"id"', '"zoom_meeting_id"', '"topic"', '"agenda"', '"duration"', '"host_email"', '"uuid"', '"created_on"', '"resource__name"', '"project__project_name"', '"is_recording_not_found"', '"project_id"', '"resource_id"'],
    MYPHONECAAL_DEATILS_COLUMNS: ['"id"', '"destination_number"', '"call_duration"', '"answer_duration"', '"direction"', '"call_date"', '"call_time"', '"state"', '"description"', '"story__summary"', '"project__project_name"', '"resource__name"', '"story_id"', '"project_id"', '"resource_id"', '"recording_url"'],
    MY_RESOURCE_CREDENTIAL_COLUMNS: ['"id"', '"status"', '"is_global"', '"resource__name"', '"credentails_head__head_name"', '"resource_id"', '"credentails_head_id"'],
    JOB_POST_LIST_COLUMNS: ['"id"', '"job_title"', '"work_mode"', '"experience_type"', '"education_qualification"', '"job_description"', '"job_experience"', '"status"', '"industry__industry_name"', '"preferred_industry"', '"project__project_name"', '"project_id"', '"industry_id"'],
    TEAM_ATTENDANCE_LIST_COLUMNS: ['"id"', '"punchin_time"', '"punchin_date"', '"punch_out_time"', '"punch_out_date"', '"break_duration_minutes"', '"work_duration_minutes"', '"extra_working_minutes"', '"resource__name"', '"resource__emp_code"', '"resource_id"'],
    MY_JOB_LIST_COLUMNS: '{"val":{"id","summary","status_id","job_id","created_on","sprint","epic_id","assignee_id","assignee","reported_by","issue_type","project_id","ticket_key","status","story_point","updated_on","is_jira_issue","priority","status_name","jira_issue_id","ticket_jira_key"}}',
    MENU_LIST_COLUMNS: ['"id"', '"title"', '"url_slug"', '"description"', '"parent_id"', '"sort_by"', '"class_name"', '"status"'],
    SKILL_LIST_COLUMNS: ['"id"', '"skill_name"', '"skill_key"', '"description"', '"status"'],
    INDUSTRY_LIST_COLUMNS: ['"id"', '"industry_name"', '"industry_type"', '"description"', '"status"'],
    THIRDPARTY_DOMAIN_LIST_COLUMNS: ['"id"', '"name"', '"code"', '"description"', '"status"'],
    QUALIFICATION_LIST_COLUMNS: ['"id"', '"education_qualification_name"', '"status"'],
    THIRD_PARTY_LIST_COLUMNS: ['"id"', '"name"', '"code"', '"description"', '"status"'],
    THIRD_PARTY_CREDENTIAL_LIST_COLUMNS: ['"id"', '"credential_head__head_name"', '"credential_head_id"', '"third_party__name"', '"third_party_id"', '"status"'],

    ATTENDANCE_PRESENSE_LIST_COLUMNS: ['"id"', '"break_duration_minutes"', '"created_on"', '"expected_punch_in_date_time"', '"expected_punch_out_date_time"', '"punchin_date"', '"punchin_time"', '"work_duration_minutes"', '"punch_out_time"', '"current_status"'],
    SOVERN_CREDENTIAL_LIST_COLUMNS: ['"id"', '"account_id"', '"created_by"', '"credit_remaning"', '"credit_used"', '"expiration_date"', '"is_active"', '"total_credits"', '"name"'],
    EMPOLYEE_TYPE_LIST_COLUMNS: ['"id"', '"employment_type"', '"status"'],
    ROLES_LIST_COLUMNS: ['"id"', '"role_description"', '"role_key"', '"role_name"', '"status"'],
    DEPARTMENT_LIST_COLUMNS: ['"id"', '"code"', '"description"', '"name"', '"status"'],
    RESOURCE_LIST_COLUMNS: ['"id"', '"name"', '"display_name"', '"email"', '"emp_code"', '"reporting_manager"', '"contact_no"', '"department_id"', '"department__name"', '"employment_type_id"', '"employment_type__employment_type"', '"tms_role_id"', '"tms_role__role_name"', '"shift__id"', '"shift__shift_name"', '"shift__end_time"', '"shift__start_time"', '"status"', '"jira_account"','"user_id"','"password"'],
    REGULARIZATION_LIST_COLUMNS: ['"id"', '"actual_punch_in_datetime"', '"actual_punch_out_datetime"', '"approved_rejected_by"', '"approved_rejected_by_id"', '"approved_rejected_on"', '"corrected_punch_in_datetime"', '"corrected_punch_out_datetime"', '"remark"',
        '"reporting_head_remark"', '"request_type"', '"resource_id"', '"resource__display_name"', '"resource__email"', '"resource_manager__display_name"', '"status"', '"resource_attendance__id"', '"resource_attendance__punchin_date"'],
    SPRINT_LIST_COLUMNS: ['"id"', '"description"', '"sprint_name"', '"is_jira_status"', '"status"'],

    //drop down columns
    RESOURCE_DROUPDOWN_COLUMNS: ['"id"', '"name"', '"display_name"', '"email"', '"reporting_manager"'],
    SKILL_DROUPDOWN_COLUMNS: ['"id"', '"skill_name"'],
    INDUSTRY_DROUPDOWN_COLUMNS: ['"id"', '"industry_name"'],
    QUALIFICATION_DROUPDOWN_COLUMNS: ['"id"', '"education_qualification_name"'],
    ROLE_DROUPDOWN_COLUMNS: ['"id"', '"role_name"'],
    DEPARTMENT_DROUPDOWN_COLUMNS: ['"id"', '"name"'],
    EMPLOYMENT_DROUPDOWN_COLUMNS: ['"id"', '"employment_type"'],
    SHIFT_DROUPDOWN_COLUMNS: ['"id"', '"shift_name"', '"start_time"', '"end_time"', '"timing_display"'],
    WORKFLOW_DROUPDOWN_COLUMNS: ['"id"', '"name"'],
    SPRINT_DROUPDOWN_COLUMNS: ['"id"', '"sprint_name"', '"jira_id"'],
    ISSUE_TYPE_DROUPDOWN_COLUMNS: ['"id"', '"issue_name"', '"jira_id"', '"icon_url"'],
    MAIN_PROJECT_DROUPDOWN_COLUMNS: ['"id"', '"project_name"', '"status"', '"is_jira_project"', '"jira_project_id"'],
    CREDENTIAL_HEAD_DROUPDOWN_COLUMNS: ['"id"', '"head_name"'],
    MENU_DROUPDOWN_COLUMNS: ['"id"', '"title"'],
    THIRD_PARTY_DROUPDOWN_COLUMNS: ['"id"', '"name"'],
    DOMAIN_DROUPDOWN_COLUMNS: ['"id"', '"name"'],


    MY_JOB_DROUPDOWN_COLUMNS: '{"val":{"id","summary","ticket_key"}}',

    RESOURCE_WISE_PROJECT_DROUPDOWN_COLUMNS: ['"id"', '"status"', '"project__project_name"', '"project__id"', '"project__jira_project_id"', '"resource__name"', '"resource__id"'],
    STORY_SCREENING_LIST_COLUMNS: ['"id"', '"story_id"', '"job_id"', '"epic_id"', '"candidate_id"', '"comment"', '"created_on"'],

    //json list columns
    MY_PROJECT_LIST_COLUMNS: ["id", "status", "project__project_name", "project__id", "project__jira_project_id", "resource__name", "resource__id", "project__project_key", "project__description", "project__is_private", "project__status", "project__created_on"],
    MY_COMP_OFF_LISTING_COLUMNS: ["id", "approved_by", "approved_minutes", "created_by", "extra_duration_minutes", "for_date", "reason", "status"],
    PROJECT_LIST_COLUMNS: ["id", "project_type", "project_name", "jira_project_id", "project_key", "description", "is_private", "status"],
    DOMAIN_LIST_COLUMNS: ["id", "name", "code", "description", "status"],
    PROJECT_DOMAIN_LIST_COLUMNS: ["id", "project_type", "project_name", "status"],
    SHIFT_LIST_COLUMNS: ["id", "shift_name", "start_time", "end_time", "shift_key", "status", "time_zone", "description"],
    RESOURCE_SHIFT_LIST_COLUMNS: ["id", "name", "display_name", "email", "emp_code", "reporting_manager", "contact_no", "department_id", "department__code", "department__name", "employment_type_id", "employment_type__employment_type", "tms_role_id", "tms_role__role_name", "tms_role__role_key", "shift__id", "shift__shift_name", "shift__end_time", "shift__start_time", "status", "jira_account"],
    WORKFLOW_LIST_COLUMNS: ["id", "code", "description", "name", "status"],
    RESOURCE_ROLES_DROUPDOWN_COLUMNS: ["id", "name", "display_name", "email", "reporting_manager", "tms_role__role_key", "tms_role_id", "tms_role__role_name"],

    //json dropdown columns
    MAIN_RESOURCE_DROUPDOWN_COLUMNS: ["id", "name", "display_name", "email", "reporting_manager"],
    RESOURCE_PROJECT_DROUPDOWN_COLUMNS: ["id", "project", "resource", "status", "project__project_name", "resource__name"],
}