import './zoomCallDetails.scss';
import { ArrowBack } from '@mui/icons-material';
import { TablePagination, Tooltip } from '@mui/material';
import { DataGrid, getGridSingleSelectOperators } from '@mui/x-data-grid'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import { zoomCallDetailsColumn } from '../../components/datatable-coulms/tableCoulmns'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from '../../redux/common-status/constant';
import { getZoomCallLogDetails } from '../../redux/features/zoomSlice';
import { Link, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { projectsListOption, resourceListOption } from '../../redux/features/optionsSlice';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { RefreshButton } from '../../components/common-function/RefreshButton';

export const ZoomCallDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { zoomCallDetails, status } = useSelector(state => state.zoomSlice)
    const { data: list } = zoomCallDetails

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.ZOOMCAAL_DEATILS_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [defaultValue, setDefaultValue] = useState({})
    const { resources, projects } = useSelector(state => state.dropdownOptions)
    const [filterResourceList, setFilterResourceList] = useState([]);
    const [filterProjectList, setFilterProjectList] = useState([]);

    useEffect(() => {
        dispatch(getZoomCallLogDetails(filterQuery))
        dispatch(resourceListOption())
        dispatch(projectsListOption())
    }, [])

    if (filterResourceList && filterResourceList.length === 0 && resources) {
        let array = []
        resources && resources.map(e => { return array.push({ value: e.id, label: e.label }) })
        array.length > 0 && setFilterResourceList(array)
        array.length > 0 && setOptions({ ...options, resource_id: array })
    }
    if (filterProjectList && filterProjectList.length === 0 && projects) {
        let array = []
        projects && projects.map(e => { return array.push({ value: e.id, label: e.project_name }) })
        array.length > 0 && setFilterProjectList(array)
        array.length > 0 && setOptions({ ...options, project_id: array })
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getZoomCallLogDetails(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getZoomCallLogDetails(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.ZOOMCAAL_DEATILS_COLUMNS}]}`

            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getZoomCallLogDetails(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            // setPageSize(10)
            setDefaultValue("")
            setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.ZOOMCAAL_DEATILS_COLUMNS}]}`)
            dispatch(getZoomCallLogDetails(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.ZOOMCAAL_DEATILS_COLUMNS}]}`))
        }
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const actionColumn = [
        {
            field: 'resource_id',
            headerName: 'Resource',
            width: 250,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.resource__name ? params.row.resource__name : "-"}
                    </div>
                )
            }
        },
        {
            field: 'project_id',
            headerName: 'Project Name',
            sortable: false,
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <Tooltip title="View Project">
                            <Link to={`/view-project`} state={{ id: params.row.project_id }}>{params.row.project__project_name}</Link>
                        </Tooltip>
                    </div>
                )
            }
        },
    ]
    const columns = useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "resource_id" || col.field === "project_id") {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        [actionColumn]
    );

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getZoomCallLogDetails(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{ "column": "-id" },"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`)
        }
    }

    return (
        <div className="zoomCallListing">
            <Sidebar />
            <div className="zoomCallListingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Zoom Call Details</h3>
                        <div>
                            <RefreshButton api={getZoomCallLogDetails} filter={filterQuery} style={{marginTop: 12, marginRight:5}} />


                            <Tooltip title="Back">
                                <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                            </Tooltip>
                        </div>
                    </div>

                    <ToastContainer />
                    {(status === STATUSES.LOADING) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={list && list.rows ? list.rows : []}
                        columns={zoomCallDetailsColumn.concat(columns)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

            </div>
        </div>
    )
}
