import "./manageProject.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../redux/APIS/api";
import { toast, ToastContainer } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import LoaderForm from "../../components/common-function/LoaderForm";
import { assignProjectDomain } from "../../redux/features/domainSlice"
import { getDepartmentList, getDepartmentDomains } from "../../redux/features/departmentSlice"
import { workFlowList } from "../../redux/features/workflowSlice"
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import parse from 'html-react-parser';
import {InputEditorField} from "../../components/common-function/InputFields";
const { REACT_APP_ENV } = process.env;


const AddProject = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const editFormData = useLocation().state;

    const { departmentList, departmentDomainsList, } = useSelector(state => state.department)
    const { workflows } = useSelector(state => state.workflow)

    const [formData, setFormData] = useState(
        editFormData ? {
            ...editFormData, project_type_default: { label: editFormData.project_type_name, value: editFormData.project_type_id },
            domains_ids: editFormData?.domains?.map(e => ({ id: e.id, label: e.name, name: "domains_ids", value: e.id })),
            workflow_id: editFormData?.workflow?.id ? { label: editFormData.workflow.name, value: editFormData.workflow.id, name: "workflow_id" } : "",
            department_id: editFormData?.department?.id ? { label: editFormData.department.name, value: editFormData.department.id, name: "department_id" } : "",
        }
            : { is_jira_project: false, status: false }
    );
    const [projectTypes, setProjectTypes] = useState([]);
    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    const userId = rtm_user.user.data.id;
    const [resources, setResources] = useState([]);
    const [assignedResources, setAssignedResources] = useState([]);
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState(editFormData && editFormData.description ? editFormData.description : "")

    // Form Change Handler
    const handleDescriptionContent=(evt, name)=>{
        var newContent = evt.editor.getData();
        setDescription(newContent)
        //setFormData({ ...formData, [name]: newContent })
    }
    const handleChange = (evt, inputName = null) => {
        if (!Array.isArray(evt)) {
            let { name, type, value, checked } = evt.target || evt;
            value = type === "checkbox" ? checked : type === "time" ? value.length < 8 ? value + ":00" : value : value;
            if (name === "department_id") {
                setFormData({ ...formData, domains_ids: [], [name]: value });
                dispatch(getDepartmentDomains(
                    {
                        "filter": {
                            "status": { "value": "True", "operation": "eq" },
                            "department_id": { "value": evt.value, "operation": "eq" }
                        }, "no_limit": "True"
                    }))
            }
            else {
                setFormData({ ...formData, [name]: value })
            }
        }
        else
            if (inputName === "domains_ids") {
                setFormData({ ...formData, [inputName]: evt })
            }
            else {
                const optArray = evt.map((e, i) => e.id);
                setFormData({ ...formData, [inputName]: optArray })
            }

    }

    // Form Submit Handler
    const handleSubmit = async (evt) => {
        evt.preventDefault();
        setLoading(true);
        const submitData = {
            "project_name": formData.project_name,
            "project_type": formData.project_type_id,
            // "project_key": formData.project_key,
            "is_jira_project": formData.is_jira_project,
            "description": description,
            "department": formData.is_jira_project ? "" : formData.department_id?.value ? formData.department_id.value : formData.department_id,
            "workflow": formData.is_jira_project ? "" : formData.workflow_id?.value ? formData.workflow_id?.value : formData.workflow_id,
            "status": formData.status,
        }

        const res = formData.id ? await api.put("/projects/main/" + formData.id, { ...submitData, updated_by: userId }) : await api.post("/projects/main", { ...submitData, created_by: userId })
        if (res && res.data && res.data.data) {
            (res.data.data.id && !res.data.data.is_jira_project && formData.domains_ids) && dispatch(assignProjectDomain({
                "project_id": res.data.data.id,
                "domains_ids": formData.domains_ids.map(e => e.value),
                "created_by": userId
            }))
            const assignedResArray = assignedResources.map(e => e.id)
            const projectResourceUpdated = formData.assignedResources && formData.assignedResources !== assignedResArray ? await updateProjectResources(formData.id ? formData.id : res.data.data.id) : true;
            if (projectResourceUpdated) {
                toast.success(res.data.message);
            } else {
                toast.success(res.data.message)
            }
            setFormData({ is_jira_project: false, status: false });
            setTimeout(() => {
                setDescription("")
                navigate("/all-projects", { replace: true })
                setLoading(false);
            }, 3000);
        } else {
            toast.error(res.data.message)
            setLoading(false);
        }
    }

    //  Update The Resource List(Assignees) of Projects
    const updateProjectResources = async (projectId) => {
        const submitData = {
            "project": projectId,
            "resource": formData.assignedResources,
            "status": true,
            "created_by": userId
        }
        const res = await api.post("/resources/resource-project", submitData);
        if (res && res.data && res.data.data) {
            return true
        } else {
            return false;
        }
    }

    const loadProjectTypes = async () => {
        const data = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" }
        const res = await api.get(`/mst/project-type?where=${JSON.stringify(data)}`)
        if (res && res.data && res.data.data.rows) {
            setProjectTypes(res.data.data.rows)
        }
    }

    const loadResources = async () => {
        const qParam = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" };
        const res = await api.get(`resources/main?where=${JSON.stringify(qParam)}`)
        if (res && res.data && res.data.data && res.data.data.rows) {
            setResources(res.data.data.rows);
        }
    }

    const loadProjectResources = async (projectId) => {
        const qParam = { "filter": { "status": { "value": "True", "operation": "eq" }, "project": { "value": projectId, "operation": "eq" } }, "no_limit": "True" };
        const res = await api.get(`/resources/resource-project?where=${JSON.stringify(qParam)}`)
        if (res && res.data && res.data.data && res.data.data.rows) {
            const resourceArray = res.data.data.rows.map((element) => {
                return element.resource
            })
            setAssignedResources(resourceArray);
        }
    }

    useEffect(() => {
        dispatch(workFlowList())
        dispatch(getDepartmentList())
        loadResources()
        loadProjectTypes()
        editFormData && editFormData.id && loadProjectResources(editFormData.id)
        editFormData && editFormData.department_id && dispatch(getDepartmentDomains(
            {
                "filter": {
                    "status": { "value": "True", "operation": "eq" },
                    "department_id": { "value": editFormData.department_id, "operation": "eq" }
                }, "no_limit": "True"
            }))
    }, [])


    return (
        <div className="new">
            <Sidebar />

            <div className="newContainer">
                <Navbar />
                <div className="top-headings">
                    <h3> {(formData && formData.id) ? "Edit Project" : "Add new Project"} </h3>
                    <Tooltip title="Back">
                        <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                <ToastContainer />
                <form onSubmit={handleSubmit}>
                    {((editFormData && editFormData.project_name) || !editFormData) &&
                        <div className="add-new-user-form">
                            {loading && <LoaderForm />}
                            <div className="formInput50">
                                <label >
                                    Project Name
                                </label>
                                <input
                                    name={"project_name"}
                                    disabled={editFormData && editFormData.project_name}
                                    defaultValue={formData && formData.project_name}
                                    onChange={handleChange}
                                    required={true}
                                    type="text"
                                    placeholder="Project Name"
                                />
                            </div>

                            <div className="formInput50">
                                <label >
                                    Project Type
                                </label>
                                <Select
                                    className="basic-single"
                                    placeholder="Select"
                                    defaultValue={formData && formData.project_type_id ? formData.project_type_default : ""}
                                    isSearchable={true}
                                    name={"project_type_id"}
                                    onChange={handleChange}
                                    options={
                                        projectTypes.map((option) => {
                                            return { label: option.project_type_name, value: option.id, name: "project_type_id" }
                                        })
                                    }
                                />

                            </div>

                            {
                                !formData.is_jira_project &&
                                <>
                                    <div className="formInput50">
                                        <label >
                                            Department
                                        </label>
                                        <Select
                                            className="basic-single"
                                            placeholder="Select"
                                            defaultValue={formData && formData.department_id ? formData.department_id : ""}
                                            isSearchable={true}
                                            name={"department_id"}
                                            onChange={(evt) => {
                                                handleChange(evt, "department_id");

                                            }}
                                            options={departmentList.map(e => ({ id: e.id, label: e.name, name: "department_id", value: e.id }))}
                                        />
                                    </div>
                                    <div className="formInput50">
                                        <label >
                                            Domains
                                        </label>
                                        <Multiselect
                                            isObject={true}
                                            onRemove={(evt) => { handleChange(evt, "domains_ids") }}
                                            onSelect={(evt) => { handleChange(evt, "domains_ids") }}

                                            displayValue={"label"}
                                            selectedValues={formData && formData.domains_ids ? formData.domains_ids : ""}
                                            options={departmentDomainsList.map(e => ({ id: e.domain.id, label: e.domain.name, name: "domains_ids", value: e.domain.id }))}
                                        />
                                    </div>
                                    <div className="formInput50">
                                        <label >
                                            Workflow
                                        </label>
                                        <Select
                                            className="basic-single"
                                            placeholder="Select"
                                            defaultValue={formData.workflow_id ? formData.workflow_id : ""}
                                            isSearchable={true}
                                            name={"workflow_id"}
                                            onChange={handleChange}
                                            options={workflows.map(e => ({ id: e.id, label: e.name, name: "workflow_id", value: e.id }))}
                                        />
                                    </div>
                                </>
                            }


                            {
                                editFormData &&
                                <div className="formInput50">
                                    <label >
                                        Is Jira Project
                                    </label>
                                    <select
                                        name={"is_jira_project"}
                                        defaultValue={formData && formData.is_jira_project}
                                        disabled={true}
                                        onChange={handleChange}
                                        required={true}
                                    >
                                        {formData && formData && !formData.is_jira_project && <option>Select</option>}
                                        <option value={true} label="">Yes</option>
                                        <option value={false} label="">No</option>
                                    </select>
                                </div>
                            }


                            <div className="formInput50">
                                <label >
                                    Resources
                                </label>
                                <Multiselect
                                    isObject={true}
                                    onRemove={(evt) => { handleChange(evt, "assignedResources") }}
                                    onSelect={(evt) => { handleChange(evt, "assignedResources") }}
                                    displayValue={"name"}
                                    selectedValues={assignedResources}
                                    options={resources}
                                />
                            </div>

                            <div className="formInput50">
                                <label >
                                    Status
                                </label>
                                <select
                                    name={"status"}
                                    defaultValue={formData && formData.status}
                                    onChange={handleChange}
                                    required={true}
                                >
                                    {!formData.status && <option>Select</option>}
                                    <option value={true} label="">Active</option>
                                    <option value={false} label="">Inactive</option>
                                </select>
                            </div>

                            <div className="formInput100">
                                {/* <label >
                                    Description
                                </label> 
                            <textarea rows="3"
                                    name={"description"}
                                    // /   defaultValue={formData && formData.description}
                                    defaultValue={formData && (formData.description)}
                                    onChange={handleChange}
                                    required={false}
                                    placeholder="Short Description"
                                >
                                </textarea> */}
                                <InputEditorField
                                        label={"Description"}
                                        defaultValue={formData && formData.description}
                                        name="description"
                                        onChange={(evt)=>handleDescriptionContent(evt, "description")}
                                        placeholder="Description"
                                        // errStatus={errStatus}
                                        // formErr={formErr.descriptionErr}
                                    />
                            </div>

                        </div>
                    }

                    <button type="submit" disabled={loading} className="btn btn-submit">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default AddProject;