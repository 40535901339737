import './phoneCallDetails.scss';
import { ArrowBack } from '@mui/icons-material';
import { TablePagination, Tooltip, FormControl, Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from '../../redux/common-status/constant';
import { getCallLogDetails, defaultCallFilter, getReportCallLogDetails, filterForPagination, getAgentWiseResource } from '../../redux/features/callingSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment';
import Select from "react-select";
import { getMyJobList, getTaskList } from '../../redux/features/taskSlice';
import { DateRangePicker } from 'rsuite';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import { StoryDetails } from '../task/StoryDetails';

const roleWiseFilter = `{"order_by":{"column":"-id"}, "no_limit":True}&columns={"val":{"id","summary","ticket_key"}}`
const { REACT_APP_ENV, REACT_APP_API_BASEURL } = process.env;

export const PhoneCallDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { callDetails, callLogReport, status, error, agentResourceList } = useSelector(state => state.callingSlice)
    const { data: list } = callDetails

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    //filter
    const [, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [, setDefaultValue] = useState({})
    //const { resources, projects } = useSelector(state => state.dropdownOptions)
    const [filterResourceList, setFilterResourceList] = useState([]);
    const [filterProjectList, setFilterProjectList] = useState([]);
    const [downliadFile, setDownloadFile] = useState(false)
    //const [filterStoryList, setFilterStoryList] = useState([]);
    const [selectResource, setSelectResource] = useState("")
    const [selectCheckResource, setSelectCheckResource] = useState(false)

    const [selectEpic, setselectEpic] = useState("")
    const [selectCheckEpic, setselectCheckEpic] = useState(true)
    const [selectedStory, setSelectedStory] = useState("")
    const [selectedCallingType, setSelectedCallingType] = useState("")
    const [selectedCheckStory, setSelectedCheckStory] = useState(true)
    const { taskList, myJobList } = useSelector(state => state.task)
    const [activeEpic, setActiveEpic] = useState(false)
    const [dateAndTime, setDateAndTime] = useState([])
    const [dateAndTimecheck, setDateAndTimecheck] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [isRecordingcheck, setisRecordingcheck] = useState(false)
    const [loading, setLoading] = useState(true)
    const pagination_filter = LocalStorageDataService.get_pagination_filter_all_call();
    const [filterQuery, setFilterQuery] = useState(pagination_filter && pagination_filter ? JSON.stringify(pagination_filter) : `{"skip":0,"limit":25,"order_by":{"column":"-id"}}`)
    const [paginationFilterstate] = useState(pagination_filter && pagination_filter ? JSON.stringify(pagination_filter) : filterQuery)

    const [filterQue, setFilterQue] = useState(localStorage.getItem(`${REACT_APP_ENV}_call_filter`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_call_filter`)) : '')
    const [columnFilter, setColumnFilter] = useState('')
    const [storyDiv, setStoryDiv] = useState(false)
    const [activeDives, setActiveDives] = useState("")

    if (loading === true && callDetails && callDetails.message) {
        if (callDetails.status === 200) {
            //toast.success(callDetails.message)
        } else {
            toast.error(callDetails.message)
        }
        //setTimeout(() => {
        setLoading(false)
        //}, 2000);
    }

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
            setDownloadFile(false)
        }
    }, [error])


    if (filterResourceList && filterResourceList.length === 0 && agentResourceList) {
        let array = []
        agentResourceList && agentResourceList.data && agentResourceList.data.rows.map(e => { return array.push({ value: e.id, label: e.display_name + "(" + e.email + ")" }) })
        array.length > 0 && setFilterResourceList(array)
    }
    if (filterProjectList && filterProjectList.length === 0 && myJobList) {
        let array = []
        myJobList && myJobList.data && myJobList.data.rows.map(e => { return array.push({ value: e.id, label: e.ticket_key }) })
        const arrayUniqueByKey = [...new Map(array.map(item =>
            [item['value'], item])).values()];
        arrayUniqueByKey.length > 0 && setFilterProjectList(arrayUniqueByKey)
    }

    if (activeEpic === true && taskList) {
        setActiveEpic(false)
        let array = []
        taskList && taskList.data && taskList.data.rows.map(e => {
            //if (e.issue_type_name === 'Story' || e.issue_type_name === 'story') {
            return array.push({ value: e.id, label: e.ticket_key })
            //}
        })
        // const arrayUniqueByKey = [...new Map(array.map(item =>
        //     [item['id'], item])).values()];
        //array.length > 0 && setFilterStoryList(array)
        array.length > 0 && setOptions({ ...options, story: array })
    }

    const onchangeEpic = (evt) => {
        setSelectedStory("")
        //setFilterStoryList([])
        setselectEpic(evt)
        setTimeout(() => {
            setActiveEpic(true)
        }, 500);

        evt && evt.value && dispatch(getTaskList(`{"filter":{"epic_id":{"value":${evt.value}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))

    }
    const refresh = () => {
        setLoading(true)
        // dispatch(getCallLogDetails(filterQuery))
        if (columnFilter) {
            dispatch(getCallLogDetails(columnFilter))
        } else {
            dispatch(getCallLogDetails(filterQuery))
        }
    }
    const clear = () => {
        setSelectedStory("")
        setSelectCheckResource(true)
        //setFilterStoryList([])
        setselectCheckEpic(true)
        setisRecordingcheck(true)
        setDateAndTime([])
        setDateAndTimecheck(true)
        setIsRecording(false)
        setSelectResource('')
        setSelectedCallingType("");
        setselectEpic("")
        setFilterQue("")
        setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`)
        localStorage.removeItem(`${REACT_APP_ENV}_call_filter`)
        setLoading(true)
        if (columnFilter) {
            dispatch(getCallLogDetails(columnFilter))
        } else {
            dispatch(getCallLogDetails(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`))
        }
    }

    const search = () => {
        let array = []
        selectResource && selectResource.length > 0 && selectResource.map((o) => array.push(o.value));
        let filterSetCondition = { "datetime": dateAndTime, 'resources': selectResource, 'epic': selectEpic, 'story': selectedStory, 'checkbox': isRecording, 'direction': selectedCallingType }
        let filterCondition = { filter: {} }
        let date_time_filter = dateAndTime && dateAndTime.length > 0 && { "value": [moment(new Date(dateAndTime[0])).utc().format('YYYY-MM-DD HH:mm:ss'), moment(new Date(dateAndTime[1])).utc().format('YYYY-MM-DD HH:mm:ss')], "operation": "range" }

        ////////////////////////////////////////////////////
        // Start Refracting
        ////////////////////////////////////////////////////

        if (dateAndTime && dateAndTime.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "created_on": date_time_filter,
                }
            }
        }

        if (selectResource && selectResource.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "resource_id": { "value": array, "operation": "in" }
                }
            }
        }
        if (selectEpic) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "epic_id": { "value": `${selectEpic.value}`, "operation": "eq" },
                }
            }
        }
        if (selectedStory) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "story_id": { "value": `${selectedStory.value}`, "operation": "eq" },
                }
            }
        }

        if (selectedCallingType) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "direction": { "value": `${selectedCallingType.value}`, "operation": "eq" },
                }
            }
        }

        if (isRecording === true) {
            filterCondition = {
                ...filterCondition,
                "is_recording_url": "True"
            }
        }
        // if (dateAndTime && dateAndTime.length > 0 && selectEpic && selectedStory) {
        //     filterCondition = {
        //         "filter": {
        //             "created_on": date_time_filter,
        //             "epic_id": { "value": `${selectEpic.value}`, "operation": "eq" }, "story_id": { "value": `${selectedStory.value}`, "operation": "eq" }
        //         }
        //     }
        // } 
        // else {
        //     filterCondition = { "skip": 0, "limit": 10, "order_by": { "column": "-id" } }
        // }

        // End Refracting

        dispatch(defaultCallFilter(filterSetCondition))
        if (isRecording === true) {
            filterCondition['is_recording_url'] = "True"
        }
        filterCondition['skip'] = 0
        filterCondition['limit'] = limit
        filterCondition['order_by'] = { "column": '-id' }
        setFilterQuery(JSON.stringify(filterCondition))
        let paginationFilter = { "skip": 0, "limit": limit, 'order_by': { "column": '-id' } }
        setpaginationFilterFunction(JSON.stringify(paginationFilter))
        setLoading(true)
        dispatch(getCallLogDetails(JSON.stringify(filterCondition)))
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
    }

    if (downliadFile === true && callLogReport && callLogReport.data) {
        let api_url = callLogReport && callLogReport.data && callLogReport.data.download_api_url;
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
            }
        }
    }

    const downloadCsvFile = () => {
        setDownloadFile(true)
        let newFilter = JSON.parse(filterQuery)
        newFilter['for_download'] = "True"
        newFilter['no_limit'] = "True"
        dispatch(getReportCallLogDetails(JSON.stringify(newFilter)))
    }

    const setDefaultFilter = () => {
        let to_date = filterQue.datetime && filterQue.datetime.length > 0 && new Date(filterQue.datetime[0])
        let to_date_1 = filterQue.datetime && filterQue.datetime.length > 0 && new Date(filterQue.datetime[1])
        let datetime = filterQue.datetime && filterQue.datetime.length > 0 ? filterQue.datetime : []
        let resources = filterQue.resources && filterQue.resources.length > 0 ? filterQue.resources : []
        let epic = filterQue.epic
        let story = filterQue.story
        let direction = filterQue.direction
        let array = []
        let check = filterQue.checkbox
        setSelectedCallingType(direction)
        setSelectResource(resources)
        setselectEpic(epic)
        setSelectedStory(story)
        to_date && setDateAndTime([(to_date), to_date_1])
        setIsRecording(check)
        if (epic) {
            setTimeout(() => {
                setActiveEpic(true)
            }, 500);

            dispatch(getTaskList(`{"filter":{"epic_id":{"value":${epic.value}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True,"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))

        }
        let filterCondition = { filter: {} }
        resources && resources.length > 0 && resources.map((o) => array.push(o.value));
        let date_time_filter = datetime && datetime.length > 0 && { "value": [moment(new Date(datetime[0])).utc().format('YYYY-MM-DD HH:mm:ss'), moment(new Date(datetime[1])).utc().format('YYYY-MM-DD HH:mm:ss')], "operation": "range" }

        // if (datetime && datetime.length > 0 && resources && resources.length > 0 && epic && story && check === true) {
        //     filterCondition = {
        //         "filter": {
        //             "created_on": date_time_filter,
        //             "resource_id": { "value": array, "operation": "in" }, "epic_id": { "value": `${epic.value}`, "operation": "eq" }, "story_id": { "value": story.value, "operation": "eq" }, "exclude": { "recording_url": { "value": "None", "operation": "eq" } }
        //         }
        //     }

        // } else 

        ///////////////////////////////////////////////
        // Cleanup Section Start
        ///////////////////////////////////////////////

        // if (filterCondition) {

        if (datetime && datetime.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "created_on": date_time_filter,
                    // "resource_id": { "value": array, "operation": "in" }, "epic_id": { "value": `${epic.value}`, "operation": "eq" }, "story_id": { "value": story.value, "operation": "eq" }
                }
            }

        }
        if (resources && resources.length > 0) {
            filterCondition = { "filter": { ...filterCondition.filter, "resource_id": { "value": array, "operation": "in" } } }

        }
        if (epic) {
            filterCondition = { "filter": { ...filterCondition.filter, "epic_id": { "value": `${epic.value}`, "operation": "eq" } } }

        }
        if (story) {

            filterCondition = { "filter": { ...filterCondition.filter, "story_id": { "value": story.value, "operation": "eq" } } }
        }

        if (direction) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "direction": { "value": `${direction.value}`, "operation": "eq" },
                }
            }
        }
        if (check === true) {
            filterCondition = { ...filterCondition.filter, "is_recording_url": "True" }

        }
        if (check !== true && !datetime && !resources && !epic && !story) {
            filterCondition = { ...filterCondition.filter, "skip": 0, "limit": limit, "order_by": { "column": "-id" } }
        }

        // Cleanup Section End

        let parse_pagination = JSON.parse(paginationFilterstate)
        filterCondition['skip'] = parse_pagination && parse_pagination.skip ? parse_pagination.skip : 0
        filterCondition['limit'] = parse_pagination && parse_pagination.limit ? parse_pagination.limit : limit
        filterCondition['order_by'] = { "column": '-id' }
        setFilterQuery(JSON.stringify(filterCondition))
        let paginationFilter = { "skip": filterCondition['skip'], "limit": filterCondition['limit'], 'order_by': { "column": '-id' } }
        setpaginationFilterFunction(JSON.stringify(paginationFilter))
        setLoading(true)
        dispatch(getCallLogDetails(JSON.stringify(filterCondition)))
        // }
    }

    useEffect(() => {
        if (location.state && location.state.filter) {
            dispatch(getCallLogDetails(JSON.stringify(location.state.filter)))
        } else if (filterQue) {
            setDefaultFilter()
        } else if (filterQue === '') {
            dispatch(getCallLogDetails(filterQuery))
        }
        dispatch(getMyJobList(roleWiseFilter))
        dispatch(getAgentWiseResource(`{"order_by":{"column":"-id"}, "no_limit":True, "columns":["id", "display_name", "email"]}`))
        setpaginationFilterFunction(paginationFilterstate)
    }, [])

    const updateDateTime = (evt) => {
        setDateAndTime(evt)
    }

    const setpaginationFilterFunction = (obj) => {
        const data = JSON.parse(obj);
        setPageSize(data.limit)
        const current = Math.ceil((data.skip) / (data.limit))
        setCurrentPage(current)
        setLimit(data.limit)
        dispatch(filterForPagination(obj))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        let filter_pagination = { "skip": newFilterData['skip'], "limit": newFilterData['limit'], 'order_by': { "column": '-id' } }
        setpaginationFilterFunction(JSON.stringify(filter_pagination))
        setLoading(true)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        let filter_pagination = { "skip": newFilterData['skip'], "limit": newFilterData['limit'], 'order_by': { "column": '-id' } }
        setFilterQuery(JSON.stringify(newFilterData))
        setpaginationFilterFunction(JSON.stringify(filter_pagination))
        setLoading(true)
        dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue
            // const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
            // setFilterQuery(filterData)
            let newFilterData
            if (filterQue) {
                if (typeof filterQuery === 'string') {
                    newFilterData = JSON.parse(filterQuery)
                } else {
                    newFilterData = filterQuery
                }
                newFilterData['filter'][filterModel.items[0].columnField] = { "value": filterModel.items[0].value, "operation": operatorValue }
                setFilterQuery(JSON.stringify(newFilterData))
                dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
                setColumnFilter(JSON.stringify(newFilterData))
            } else {
                newFilterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                setFilterQuery(newFilterData)
                dispatch(getCallLogDetails((newFilterData)))
                setColumnFilter((newFilterData))
            }
            setCurrentPage(0)
            // dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setColumnFilter('')
            setCurrentPage(0)
            setDefaultValue("")
            setFilterQuery(filterQuery)
            dispatch(getCallLogDetails(filterQuery))
        }
    }

    const activeStoryDiv = (id) => {
        setActiveDives(`story_${id}`)
        if (storyDiv > 0) { setStoryDiv(id) } else { setStoryDiv(id); }
    }

    const nothing = () => { }

    const phoneCallDetailsColumns = [
        { field: 'id', headerName: 'ID', width: 10, filterable: false },
        { field: 'destination_number', headerName: 'Destination Number', width: 160, },
        { field: 'resource_name', headerName: 'Resource', width: 250, filterable: false },
        { field: 'call_duration', headerName: 'Call Duration(in sec)', width: 200 },
        { field: 'answer_duration', headerName: 'Actual Duration(in sec)', width: 200 },
        {
            field: "direction", headerName: "Call Type", width: 200,
            type: 'singleSelect',
            filterable: false,
            valueOptions: [{ value: 0, label: 'In-bound' }, { value: 1, label: 'Out-bound' }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.direction}`}>
                        {params.row.direction === 1 ? 'Out-bound' : 'In-bound'}
                    </div>
                );
            },
        },
        {
            field: "call_date", headerName: "Call Date", type: 'date', width: 200, filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.call_date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        {
            field: 'call_time',
            headerName: 'Call Time',
            width: 150, filterable: false
        },
        // { field: 'state', headerName: 'State', width: 180 },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.description ? params.row.description : ""} >
                        <div className="cellWithStatus">
                            {params.row.description}
                        </div>
                    </Tooltip >
                )
            },
            //  filterable: false
        },
        {
            field: 'epic_ticket_key',
            headerName: 'Jobs',
            width: 200, filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {
                            params.row.epic_id ?
                                <Tooltip title="View Jobs">
                                    <Link to={`/epic-task/${params.row.epic_id}`} >{params.row.epic_ticket_key}</Link>
                                </Tooltip> : "-"
                        }
                    </div>
                )
            }
        },
        {
            field: 'story_ticket_key',
            headerName: 'Resumes',
            width: 290,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {
                            params.row.story_id ?
                                <div style={{ cursor: 'pointer', color: '#0052cc' }} className="cellWithStatus" onClick={() => params.row.story_id ? activeStoryDiv(params.row.story_id) : nothing()}>
                                    <Tooltip title="View Story">
                                        <p>{params.row.story_ticket_key}</p>
                                    </Tooltip>
                                </div>
                                // <Tooltip title="View Resumes">
                                //     <Link to={`/story-task/${params.row.story_id}`}>{params.row.story_ticket_key}</Link>
                                // </Tooltip>

                                : "-"
                        }
                    </div>
                );
            }
        },
        {
            field: 'recording_url',
            headerName: 'Call Recording Link',
            width: 350,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.recording_url ?
                            <ReactAudioPlayer
                                src={params.row.recording_url}
                                autoPlay={false}
                                controls
                                style={{ height: '30px' }}
                            />
                            : "-"}
                    </div>
                )
            }
        },
    ]

    let i = 0
    return (
        storyDiv > 0 ?
            <div className="task">
                <Sidebar />
                <div className="taskContainer">
                    <Navbar />
                    <StoryDetails
                        epicId={storyDiv}
                        hideDiv={setStoryDiv}
                        activeDivs={activeDives}
                    />
                </div>
            </div>
            :
            <div className="phoneCallListing">
                <Sidebar />
                <div className="phoneCallListingContainer">
                    <Navbar />
                    <div className="datatable">
                        <div className="datatableTitle">
                            <h3>All Phone Call Details</h3>
                            <div>
                                <RefreshButton api={getCallLogDetails} filter={filterQuery} style={{ marginRight: 5 }} />
                                <Tooltip title="Back">
                                    <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                                </Tooltip>
                            </div>
                        </div>

                        <ToastContainer />
                        {(loading === true || downliadFile === true || status === `${STATUSES.LOADING}__callDetails`) && <LoaderForm />}
                        {/* <div style={{ display: "flex", justifyContent: "space-betwwen", marginTop: "30px", marginBottom: "30px" }}> */}

                        <div style={{ display: "flex", width: '100%', height: '30px', marginTop: '20px' }}>
                            <FormControl style={{ flexBasis: "36.5%" }}>
                                <DateRangePicker
                                    format="yyyy-MM-dd hh:mm aa"
                                    placeholder="yyyy-mm-dd hh:mm aa ~ yyyy-mm-dd hh:mm aa"
                                    showMeridian
                                    // value={dateAndTimecheck === false && filterQue.datetime && filterQue.datetime.length > 0 ? [(new Date(filterQue.datetime[0])), new Date(filterQue.datetime[1])] : dateAndTime}
                                    //defaultCalendarValue={dateAndTimecheck === false && filterQue.datetime && filterQue.datetime.length > 0 ? [(new Date(filterQue.datetime[0])), new Date(filterQue.datetime[1])] :[(fromDate), toDate]}
                                    value={dateAndTimecheck === false && filterQue.datetime && filterQue.datetime.length > 0 ? [(new Date(filterQue.datetime[0])), new Date(filterQue.datetime[1])] : dateAndTime}
                                    onChange={(evt) => updateDateTime(evt)}
                                    onClean={(evt) => {
                                        setFilterQue({ ...filterQue, datetime: [] }); updateDateTime([])
                                    }}
                                />
                            </FormControl>
                            {/*select resource section*/}
                            <FormControl style={{ flexBasis: "36%", marginLeft: "8px" }}>
                                <Select
                                    placeholder="Select Job"
                                    isClearable
                                    value={(selectCheckEpic === false && filterQue.epic) ? filterQue.epic : selectEpic}
                                    onChange={(evt) => { onchangeEpic(evt); setselectCheckEpic(true) }}
                                    width='300px'
                                    options={filterProjectList} />
                            </FormControl>
                            {/*select resource section*/}
                            <FormControl style={{ flexBasis: "30%", marginLeft: "8px" }}>
                                <Select
                                    isDisabled={activeEpic === true ? true : false}
                                    placeholder="Select Resume"
                                    isClearable
                                    value={selectedCheckStory === false && filterQue.story ? filterQue.story : selectedStory}
                                    onChange={(evt) => { setSelectedStory(evt); setSelectedCheckStory(true) }}
                                    width='300px'
                                    //options={filterStoryList}
                                    options={selectEpic ? taskList && taskList.data && taskList.data.rows && taskList.data.rows.length > 0 ? taskList.data.rows.map(e => { return { value: e.id, label: e.ticket_key } }) : [] : []}
                                />
                            </FormControl>
                            {/*select resource section*/}
                        </div>
                        <div style={{ display: "flex", width: '100%', marginTop: 20, marginBottom: 30 }}>
                            <FormControl style={{ flexBasis: "25%", }}>
                                <Select
                                    placeholder="Select Resources"
                                    isMulti
                                    value={selectCheckResource === false && filterQue.resources && filterQue.resources.length > 0 ? filterQue.resources : selectResource}
                                    onChange={(evt) => { setSelectResource(evt); setSelectCheckResource(true) }}
                                    width='300px'
                                    options={filterResourceList} />
                            </FormControl>
                            {/*select CallType section*/}
                            <FormControl style={{ flexBasis: "25%", marginLeft: "8px" }}>
                                <Select
                                    placeholder="Select Call Type"
                                    isClearable
                                    value={selectedCallingType === false && filterQue.direction ? filterQue.direction : selectedCallingType}
                                    onChange={(evt) => { setSelectedCallingType(evt); setFilterQue({ ...filterQue, direction: evt }) }}
                                    width='300px'
                                    options={[
                                        { id: 1, label: "In-bound", value: 0 },
                                        { id: 2, label: "Out-bound", value: 1 },
                                    ]}
                                />
                            </FormControl>
                            <FormControl>
                                <Checkbox onChange={() => { setIsRecording(!isRecording); setisRecordingcheck(true) }} checked={isRecordingcheck === false && filterQue.checkbox === true ? true : isRecording} />
                            </FormControl>
                            <p style={{ textAlign: 'left', flexBasis: "26%", marginTop: '11px' }}>Filter list with phone call recording</p>
                            <button disabled={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? { flexBasis: "auto", marginLeft: "141px", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "141px" }} onClick={() => search()} >Search</button>
                            <button disabled={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? { flexBasis: "auto", marginLeft: "6px", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "6px" }} onClick={() => clear()} >Clear</button>
                            <button disabled={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? { flexBasis: "auto", marginLeft: "6px", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "6px" }} onClick={() => refresh()}>Refresh</button>
                            <button disabled={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}_callLogReport`) ? { flexBasis: "auto", marginLeft: "6px", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "6px" }} onClick={() => downloadCsvFile()} >CSV</button>
                        </div>



                        {/* </div> */}
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={list && list.rows ? list.rows : []}
                            columns={phoneCallDetailsColumns}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            getRowId={(row) => row + i++}

                            onFilterModelChange={onFilterChange}
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            filterMode="server"
                        />
                    </div>

                </div>
            </div>
    )
}
